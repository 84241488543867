import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import NavBar from './NavBar';
import InsertContact from './InsertContact';
import UpdateContact from './UpdateContact';
import SearchContactList from '../Function/ContactList';


export default function ContactService() {
    const [ContactData, setContactData] = useState({});
    const [UpdateStatus, setUpdateStatus] = useState(false);
    const [ContactMode, setContactMode] = useState('');

    useEffect(() => {
        if(UpdateStatus){
          setUpdateStatus(false);
        }
      
    }, [UpdateStatus]);

      const handleSetUpdateStatus = (status) => {
        setUpdateStatus(status);
    };

    const handleSetContactData = (Data) => {
        setContactData(Data);
        setContactMode('Update');
    };

    return (
        <>
            <NavBar/>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-4">
                        <SearchContactList ContactData={handleSetContactData} UpdateStatus={UpdateStatus}/>
                    </div>
                    <div className="col-md-8">
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            ContactMode == 'Insert' ? (
                                                <button className="btn btn-warning col-12" onClick={() => setContactMode('Update')}>
                                                    เปลี่ยนโหมดเป็น <i className="bi bi-pencil-square" /> แก้ไขรายการ
                                                </button>
                                            ) : ContactMode == 'Update' ? ( 
                                                <button className="btn btn-primary col-12" onClick={() => setContactMode('Insert')}>
                                                    เปลี่ยนโหมดเป็น <i className="bi bi-plus-circle" /> เพิ่มรายการใหม่
                                                </button>
                                            ) : (
                                                <div className='row'>
                                                    <div className="col-6">
                                                        <button className="btn btn-primary col-12" onClick={() => setContactMode('Insert')}>
                                                            <i className="bi bi-plus-circle" /> เพิ่มรายการใหม่
                                                        </button>
                                                    </div>
                                                    <div className="col-6">
                                                        <button className="btn btn-warning col-12" onClick={() => setContactMode('Update')}>
                                                            <i className="bi bi-pencil-square" /> แก้ไขรายการ
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    {
                                        ContactMode == 'Insert' ? (
                                            <InsertContact UpdateStatus={handleSetUpdateStatus}/>
                                        ) : ContactMode == 'Update' ? (
                                            <UpdateContact ContactData={ContactData} UpdateStatus={handleSetUpdateStatus}/>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
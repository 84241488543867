function RegisterValidation(values){
    let error = {}
    const email_pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    // const password_pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
    const username_pattern = /^[a-zA-Z0-9_-]{5,}$/
    const namePattern = /^[a-zA-Z]+$/

    // else if(!password_pattern.test(values.password)){
    //     error.password = "Your password was wrong pattern !"
    // } else if(!password_pattern.test(values.password_confirm)){
    //     error.password = "Your password was wrong pattern !"
    // }

    if(values.firstname === ""){
        error.firstname = "Please enter your firstname."
    } else if(values.lastname === ""){
        error.lastname = "Please enter your lastname."
    } else if(!namePattern.test(values.firstname)){
        error.firstname = "Your firstname was wrong pattern"
    } else if(!namePattern.test(values.lastname)){
        error.lastname = "Your lastname was wrong pattern"
    } else if(values.email === ""){
        error.email = "Please enter your email."
    } else if(!email_pattern.test(values.email)){
        error.email = "Your email was wrong pattern !"
    } else if(values.password === ""){
        error.password = "Please enter your password."
    } else if(values.password_confirm === ""){
        error.password = "Please enter your password confirm."
    }  else if(values.username === ""){
        error.username = "Please enter your username."
    } else if(!username_pattern.test(values.username)){
        error.username = "Your username was wrong pattern !"
    } else if(values.password_confirm !== values.password){
        error.password_confirm = "Please enter password confirm same value."
    } else if(values.password < 8){
        error.password = "Password must be more than 8 characters."
    } 

    // if(JSON.stringify(error).lenght == 0){
    //     error.email = "";
    //     error.username = "";
    //     error.firstname = "";
    //     error.lastname = "";
    //     error.password = "";
    //     error.password_confirm = "";
    // }

    return error;

    
}

export default RegisterValidation;
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Document, Page, Image, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import bigInt from 'big-integer';

// Function to generate PromptPay payload
const generatePromptPayPayload = (mobileNumber, amount) => {
    const payloadFormatIndicator = '000201';
    const pointOfInitiationMethod = amount ? '010212' : '010211'; // Static (011) or Dynamic (012)
    const merchantAccountInfoTemplateId = '2937';
    const guid = '0016A000000677010111';
    const countryCode = '5802TH';
    const currency = '5303764';
  
    // Format mobile number (remove first zero and add country code prefix)
    const formattedMobileNumber = `0066${mobileNumber.substring(1)}`;
    const mobileNumberTag = `010${formattedMobileNumber.length.toString().padStart(2, '0')}${formattedMobileNumber}`;
  
    // Calculate CRC (Cyclic Redundancy Check)
    const calculateCRC16 = (payload) => {
      let crc = 0xFFFF;
      for (let i = 0; i < payload.length; i++) {
        crc ^= payload.charCodeAt(i) << 8;
        for (let j = 0; j < 8; j++) {
          if (crc & 0x8000) {
            crc = (crc << 1) ^ 0x1021;
          } else {
            crc = crc << 1;
          }
        }
        crc &= 0xFFFF;
      }
      return crc.toString(16).toUpperCase().padStart(4, '0');
    };
  
    const payloadWithoutCRC = [
      payloadFormatIndicator,
      pointOfInitiationMethod,
      merchantAccountInfoTemplateId,
      guid,
      mobileNumberTag,
      countryCode,
      currency,
      amount ? `54${amount.toString().length.toString().padStart(2, '0')}${amount}` : '',
      '6304', // CRC start tag
    ].join('');
  
    const crc = calculateCRC16(payloadWithoutCRC + '6304');
    const fullPayload = `${payloadWithoutCRC}${crc}`;
  
    return fullPayload;
  };
  
  // Function to generate QR code data URL
  const generateQRCodeDataURL = async (text) => {
    try {
      const url = await QRCode.toDataURL(text);
      return url;
    } catch (err) {
      console.error(err);
    }
  };

const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  qrCode: {
    width: 256,
    height: 256,
  },
});


const PromptPayQRCode = async ({ mobileNumber, amount }) => {
    const [QRCodeDataURL, setQRCodeDataURL] = useState("");
    const payload = generatePromptPayPayload(mobileNumber, amount);
    const qrCodeDataURL = await generateQRCodeDataURL(payload);
    setQRCodeDataURL({qrCodeDataURL});
    return (QRCodeDataURL);
  };

export default PromptPayQRCode;
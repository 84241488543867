import {React, useEffect, useState} from 'react'
import axios from 'axios'
import ShortText from './Text';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

export default function SearchProductList({BillData, BillReference, AllItemBag}) {
    const [ProductList, setProductList] = useState([]);
    const [SearchKeywords, setSearchKeywords] = useState('');
    const MySwal = withReactContent(Swal);

    const handleSearchProduct = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/SearchProductList`, {
                SearchKeywords: SearchKeywords
            },
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            });
            if(res.status == 200){
                setProductList(res.data);
                console.log(ProductList);
            }

        } catch (err) {
            console.error('Error fetching bill history', err);
        }
    }

    // const handleSearchProduct = async () => {
    //     try {
    //         // Delay for 3 seconds (3000 milliseconds)
    //         await new Promise(resolve => setTimeout(resolve, 1500));
    
    //         const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/SearchProductList`, {
    //             SearchKeywords: SearchKeywords
    //         },
    //         {
    //             headers: {
    //                 "Access-Control-Allow-Origin": "*",
    //                 "Content-Type": "application/json",
    //             },
    //         });
            
    //         if(res.status === 200){
    //             setProductList(res.data);
    //             console.log(ProductList);
    //         }
    
    //     } catch (err) {
    //         console.error('Error fetching product list', err);
    //     }
    // }
    


    const handleChangeKeywords = (e) => {
        setSearchKeywords(e.target.value);
        // handleSearchProduct();
    }


    useEffect(() => {
        if (SearchKeywords) {
          handleSearchProduct();
        } else {
            handleSearchProduct();
        }
      }, [SearchKeywords]);
      
    
      const ProductComponent = ({ ProductList }) => {
        const productComponents = [];
    
        if (Object.keys(ProductList).length > 0) {
            for (let id = 0; id < ProductList.length; id++) {
                const Product = ProductList[id];
                productComponents.push(
                    <li key={Product.pro_id} onClick={() => handleAddItem(Product.pro_barcode)} className="list-group-item cursor-pointer">
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="row">
                                <div className="">
                                    {ShortText(Product.pro_name, 35)}
                                </div>
                            </div>
                            <span className="badge text-bg-secondary rounded-pill">
                                {"#"+Product.pro_barcode+" "}
                            </span>   
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 text-start">
                                <span className="badge text-bg-success rounded-pill">
                                    {Product.pro_priceA == 0 ? '0.00' : Product.pro_priceA}
                                </span>
                            </div>
                            <div className="col-4 text-center">
                                <span className="badge text-bg-warning rounded-pill">
                                    {Product.pro_priceB == 0 ? '0.00' : Product.pro_priceB}
                                </span>
                            </div>
                            <div className="col-4 text-end">
                                <span className="badge text-bg-danger rounded-pill">
                                    {Product.pro_priceC == 0 ? '0.00' : Product.pro_priceC}
                                </span>
                            </div>
                        </div>
                    </li>
                );
            }
        }
    
        return (
            <>
                {
                    productComponents.length > 0 ? (
                        <ol className="list-group mt-3">{productComponents}</ol>
                    ) : null
                }
            </>
        );
    };

    const handleAddItem = (Barcode) => {
        AddItemInBag(Barcode);
    }

    const AddItemInBag = async (Barcode) => {
        if(AllItemBag.length >= 15){
            SwalShow(false, "เพิ่มสินค้าไม่ได้เนื่องจากสินค้าครบ 15 รายการแล้ว");
        } else {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/AddItemInBag/${BillReference}/${Barcode}`, 
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                });
                if(res.data.status == true){
                    // SwalShow(res.data.status, res.data.message);
                }
                if(res.data.status == false){
                    SwalShow(res.data.status, res.data.message);
                }
                console.log(res);
            } catch (err) {
                console.error('Error fetching bill history', err);
            }
        }
      }

      const SwalShow = (status, message) => {
        if(status == true){
            MySwal.fire({
                title: 'สำเร็จ',
                text: message,
                icon: 'success'
            })
        } else{
            MySwal.fire({
                title: 'ไม่สำเร็จ',
                text: message,
                icon: 'error'
            })
        }
    };
    
    
  return (
    <>
        {
            BillData.st_status == 'process' ? (
                <>
                    {
                        AllItemBag.length < 15 ? (
                            <>
                                <input type="text" onChange={handleChangeKeywords} value={SearchKeywords} className='col-12 form-control' placeholder='ค้นหารายการสินค้า' />
                                <div className="row">
                                    <div className="col-12 scrollspy-example scrollspy-pos">
                                        <ProductComponent ProductList={ProductList} />
                                    </div>
                                </div>    
                            </>
                        ) : null
                    }
                    
                </>
            ) : null
        }
    </>
    
  )
}

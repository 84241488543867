// src/PosProductForm.js
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import ProductValidate from '../Function/ProductValidation';
import uniqid from 'uniqid';
import moment from 'moment';
import BarcodeGen from '../Function/BarcodeGenerater';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const InsertCategoryandUnit = ({UpdateStatus}) => {
  const MySwal = withReactContent(Swal);
  const SwalShow = (status, message) => {
    if(status == true){
        MySwal.fire({
            title: 'สำเร็จ',
            text: message,
            icon: 'success'
        })
    } else{
        MySwal.fire({
            title: 'ไม่สำเร็จ',
            text: message,
            icon: 'error'
        })
    }
  };

  const [Categories, setCategories] = useState([]);
  const [Units, setUnits] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [NameList, setNameList] = useState('');

  

  const RequestCategories = async() => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/Product/Categories`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      setCategories(res.data);
    } catch (err) {
      console.error('Error fetching bill history', err);
    }
  }

  const RequestUnits = async() => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/Product/Units`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      setUnits(res.data);
    } catch (err) {
      console.error('Error fetching bill history', err);
    }
  }


  const InsertCategory = async(NameList) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/Product/Categories/Insert`, {
        CategoryName: NameList
      }, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      if(res.data.Status == true){
        SwalShow(true, "สำเร็จ");
        setNameList('');
        setInsertMode('กรุณาเลือก');
        UpdateStatus(true);
      } else{
        SwalShow(false, "รายการซ้ำ");
      }
    } catch (err) {
      console.error('Error fetching bill history', err);
    }
  }

  const InsertUnit = async(NameList) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/Product/Units/Insert`, {
        UnitName: NameList
      }, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      if(res.data.Status == true){
        SwalShow(true, "สำเร็จ");
        setNameList('');
        setInsertMode('กรุณาเลือก');
        UpdateStatus(true);
      } else{
        SwalShow(false, "รายการซ้ำ");
      }
    } catch (err) {
      console.error('Error fetching bill history', err);
    }
  }


  useEffect(() => {
    RequestCategories();
    RequestUnits();
  
  }, []);

  const increment = useCallback(() => {
    RequestCategories();
    RequestUnits();
  }, [Categories, Units, FormData]);
  
  const [InsertMode, setInsertMode] = useState('กรุณาเลือก');

  const handleChangeMode = (e) => {
    if(e.target.value == "Category"){
        setInsertMode('Category');
        RequestCategories();
        increment();
    } else if(e.target.value == "Unit"){
        setInsertMode('Unit');
        RequestUnits();
        increment();
    } else{
        setInsertMode('กรุณาเลือก');
        increment();
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(NameList && NameList !== ""){
      if(InsertMode == "Category"){
        InsertCategory(NameList);
      } else if(InsertMode == "Unit"){
        InsertUnit(NameList);
      }
    } else{
      SwalShow(false,  'กรุณากรอกชื่อของรายการที่เลือก');
    }
  }

  const handleChangeName = (e) => {
    setNameList(e.target.value);
  }

  return (
    <div onClick={increment}>
      <form onSubmit={handleSubmit}>
        <div className="row">
            <div className="col-2">
                <label htmlFor="">ประเภทรายการ</label>
                <select name="InsertCategoryandUnit" className="form-select" onChange={e => handleChangeMode(e)} value={InsertMode}>
                    <option value="กรุณาเลือก">กรุณาเลือก</option>
                    <option value="Category">หมวดหมู่สินค้า</option>
                    <option value="Unit">หน่วยนับสินค้า</option>
                </select>
            </div>
            {
              InsertMode === 'Category' && (
              <div className="col">
                <label htmlFor="">หมวดหมู่และหน่วยนับที่เคยบันทึก</label>
                <select className="form-select">
                  <option value="กรุณาเลือก">ดูหมวดหมู่เก่า</option>
                  {
                    Categories.map((list) => (
                      <option key={list.ctg_name} value={list.ctg_name}>{list.ctg_name}</option>
                  ))}
                </select>
              </div>
            )}
            {
              InsertMode === 'Unit' && (
                <div className="col">
                  <label htmlFor="">หมวดหมู่และหน่วยนับที่เคยบันทึก</label>
                  <select className="form-select">
                    <option value="กรุณาเลือก">ดูหน่วยนับเก่า</option>
                    {
                      Units.map((list) => (
                        <option key={list.unt_name} value={list.unt_name}>{list.unt_name}</option>
                    ))}
                  </select>
                </div>
            )}
            <div className="col">
                <label htmlFor="">ชื่อของหมวดหมู่หรือหน่วยนับ</label>
                <input type="text" name='CategoryAndUnit' onChange={handleChangeName} className='form-control' />
            </div>
            <div className="col-12">
              {
                InsertMode == 'Unit' || InsertMode == 'Category' ? (
                  <button type='submit' className="btn btn-secondary col-12 mt-4" >ยืนยันเพิ่มรายการ</button>
                ) : null
              }
            </div>
        </div>

      </form>
    </div>
  );
    
    
};

export default InsertCategoryandUnit;

import { useState, useRef, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import BillNumber from '../../Function/BillNumber';
import ShortText from '../../Function/Text';
import Webcam from 'react-webcam';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';



export default function ImageUpload({BillReference, BillData}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const MySwal = withReactContent(Swal);
    const [ImageExist, setImageExist] = useState('');

    const SwalShow = (status, message) => {
        if(status == true){
            MySwal.fire({
                title: 'สำเร็จ',
                text: message,
                icon: 'success'
            })
        } else{
            MySwal.fire({
                title: 'ไม่สำเร็จ',
                text: message,
                icon: 'error'
            })
        }
    };

    const isDocument = (imageSrc) => {
        // Placeholder function to simulate document detection
        // Replace with actual model inference logic
        const documentKeywords = ['doc', 'invoice', 'receipt', 'contract'];
        const randomIndex = Math.floor(Math.random() * documentKeywords.length);
        return Math.random() > 0.5 ? `Detected: ${documentKeywords[randomIndex]}` : 'Not a document';
      };

    const webcamRef = useRef(null);
    const [snapshot, setSnapshot] = useState(null);
    const [prediction, setPrediction] = useState('Not a document');
    

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setSnapshot(imageSrc);

    const result = isDocument(imageSrc);
        setPrediction(result);
    }, [webcamRef]);


    const handleSaveImage = async() => {
        if(snapshot != null){
            console.log(snapshot);
            try{
                const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/InvoiceSaveImage/${BillReference}`, 
                    {
                        dataImage: snapshot
                    },
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                        },
                    });
                      if(res.data.Status == true){
                        SwalShow(true, res.data.message);
                        handleGetImage();
                      } 
            } catch(err){
                console.log(err);
            }
        }
    }

    const handleGetImage = async() => {
        try{
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/RequestInvoiceImage/${BillReference}`, 
                {
                    RequestPath: BillData.customer_invoice_image
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                })
                    if(res.data.Status == true){
                        setImageExist(res.data.ImageData);
                    } 
                    console.log(res);
        } catch(err){
            console.log(err);
        }
        
    }

    useEffect(() => {
    }, [])

    const videoConstraints = {
        width: 1920,
        height: 1080,
    };
    

  return (
    <>
        {
            BillData.customer_bill_file == 'write' ? (
                <button type="button" onClick={handleShow} className="btn btn-dark col-12">
                    <i className="bi bi-file-earmark-arrow-up" /> อัปโหลดภาพใบเสร็จ
                </button>
            ) : (
                <button type="button" className="btn btn-light col-12">
                    <i className="bi bi-database-check" /> ใบเสร็จในระบบ
                </button>
            )
        }
        <Modal show={show} onShow={handleGetImage} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" onClick={handleGetImage} centered>
            <Modal.Header>
                <Modal.Title>
                    <h5 className="modal-title" id="exampleModalLabel">อัปโหลดภาพใบเสร็จ</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body">
                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="col-12">
                                            <h5 className='text-start'>กรุณาถ่ายภาพใบเสร็จ</h5>
                                            <Webcam className='img-fluid rounded' audio={false} ref={webcamRef} screenshotFormat="image/jpeg" width={1920} height={1080} videoConstraints={videoConstraints} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {
                                            snapshot ? (
                                                prediction == 'Not a document' ? (
                                                    <>
                                                        <div className="col-12">
                                                            <h5 className='text-danger'>ไม่พบใบเสร็จกรุณาถ่ายใหม่</h5>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="col-12">
                                                            <h5 className='text-start'>ถ่ายแล้ว</h5>
                                                            <img className='img-fluid rounded' src={snapshot} alt="Snapshot" />
                                                        </div>
                                                    </>
                                                )
                                            ) : null
                                        }
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <div className="col-12">
                                            <button className='btn btn-primary col-12' onClick={capture}>ถ่ายภาพ</button>
                                        </div>
                                    </div>
                                    
                                    {
                                        snapshot ? (
                                            prediction != 'Not a document' ? (
                                                <>
                                                    <div className="col-6">
                                                        <Button type='button' onClick={handleSaveImage} className='btn btn-secondary col-12'>จัดเก็บ</Button>
                                                    </div>
                                                </>
                                            ) : null
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        BillData.customer_invoice_image != null ? (
                            <div className="row">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-md-12 mb-2">
                                            <h5 className="text-start">ภาพที่จัดเก็บแล้ว</h5>
                                            <div className="text-center">
                                                <img src={ImageExist} className='img-fluid rounded' alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </Modal.Body>
            <div className="modal-footer" style={{justifyContent: 'center', display: 'block'}}>
                <div className="row">
                    <div className="col-md-12">
                        <Button type='button' onClick={handleClose} className='col-12' variant="outline-secondary">ยกเลิก</Button>
                    </div>
                </div>
            </div>
        </Modal>
    </>
  )
}

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import InsertProductForm from './InsertProduct';
import NavBar from './NavBar';
import PosSearchProductList from '../Function/PosSearchProductList';
import UpdateProductForm from './UpdateProduct';
import InsertCategoryandUnit from './InsertCategoryAndUnit';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';


export default function PosProduct() {
    const MySwal = withReactContent(Swal);
    const [ProductData, setProductData] = useState({});
    const [ProductDataMode, setProductDataMode] = useState('');
    const [UpdateStatus, setUpdateStatus] = useState(false);

    const handleSetProductData = (Data) => {
        setProductData(Data);
        setProductDataMode('Update');
    };

    const handleSetUpdateStatus = (status) => {
        setUpdateStatus(status);
    };

    useEffect(() => {
      if(UpdateStatus){
        setUpdateStatus(false);
      }
      if(ProductDataMode == 'Insert'){
        let timerInterval = 0;
        MySwal.fire({
            position: "top-end",
            icon: "warning",
            title: "กรุณาพิมพ์คำศัพท์บันทึกชื่อสินค้าให้ถูกต้อง",
            confirmButtonText: "ค้นหาคำศัพท์ที่ถูกต้อง",
            showConfirmButton: true,
            timer: 10000,
            timerProgressBar: true,
        }).then((result) => {
            if (result.isConfirmed) {
                window.location = 'https://dictionary.orst.go.th/';
            } 
        });
      }
    
    }, [UpdateStatus, ProductDataMode]);
    


    return (
        <>
            <NavBar/>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-4">
                        <PosSearchProductList ProductData={handleSetProductData} UpdateStatus={UpdateStatus}/>
                    </div>
                    <div className="col-md-8">
                        <div className="row mb-4">
                            <div className="col-md-12">
                                {
                                    ProductDataMode == 'Insert' ? (
                                        <>
                                            <div className="row">
                                                <div className="col-6">
                                                    <button className="btn btn-warning col-12" onClick={() => setProductDataMode('Update')}>
                                                        เปลี่ยนโหมดเป็น <i className="bi bi-pencil-square" /> แก้ไขรายการ
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    <button className="btn btn-primary col-12" onClick={() => setProductDataMode('InsertCategoryAndUnit')}>
                                                        เปลี่ยนโหมดเป็น <i className="bi bi-plus-circle" /> เพิ่มหมวดหมู่และหน่วยนับ
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    ) : ProductDataMode == 'Update' ? (
                                        <button className="btn btn-primary col-12" onClick={() => setProductDataMode('Insert')}>
                                           เปลี่ยนโหมดเป็น <i className="bi bi-plus-circle" /> เพิ่มรายการสินค้าใหม่
                                        </button>
                                    ) : ProductDataMode == 'InsertCategoryAndUnit' ? (
                                        <>
                                            <div className="row">
                                                <div className="col-6">
                                                    <button className="btn btn-warning col-12" onClick={() => setProductDataMode('Update')}>
                                                        เปลี่ยนโหมดเป็น <i className="bi bi-pencil-square" /> แก้ไขรายการ
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    <button className="btn btn-primary col-12" onClick={() => setProductDataMode('Insert')}>
                                                        <i className="bi bi-plus-circle" /> เพิ่มรายการสินค้าใหม่
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className='row'>
                                            <div className="col-8">
                                                <div className="btn-group col-12" role="group">
                                                    <button type="button" className="btn btn-primary myinputgroup-span1 col-6" onClick={() => setProductDataMode('Insert')}>
                                                        <i className="bi bi-plus-circle" /> เพิ่มรายการสินค้า
                                                    </button>
                                                    <button type="button" className="btn btn-primary myinputgroup-span2 col-6" onClick={() => setProductDataMode('InsertCategoryAndUnit')}>
                                                        <i className="bi bi-plus-circle" /> เพิ่มหมวดหมู่และหน่วยนับ
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <button className="btn btn-warning col-12" onClick={() => setProductDataMode('Update')}>
                                                    <i className="bi bi-pencil-square" /> แก้ไขรายการ
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                                {/* <button className="btn btn-danger col-12">
                                    <i className='bi bi-trash'/> ลบรายการนี้
                                </button> */}
                            </div>
                        </div>
                        {
                            ProductDataMode == 'Update' ? (
                                <UpdateProductForm ProductData={ProductData} UpdateStatus={handleSetUpdateStatus} />
                            ) : ProductDataMode == 'Insert' ? (
                                <InsertProductForm UpdateStatus={handleSetUpdateStatus} />
                            ) : ProductDataMode == 'InsertCategoryAndUnit' ? (
                                <InsertCategoryandUnit UpdateStatus={handleSetUpdateStatus} />
                            ) : null
                        }

                    </div>
                </div>
            </div>
        </>
    )
}
import axios from 'axios';
import React,{useCallback, useState, useEffect} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import NavBar from './NavBar';
import PosSearchProductList from '../Function/PosSearchProductList';
import ShortText from '../Function/Text';
import QuotationShowProductList from './QuotationShowProductList';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import QuotationUpdateModal from './Modal/QuotationUpdateModal'

export default function Quotation() {
    // let location = useLocation();
    let navigate = useNavigate();
    let {QuotationReference} = useParams();
    const [QuotationReferenceExisted, setQuotationReferenceExisted] = useState("");
    const [QuotationCreatedData, setQuotationCreatedData] = useState({
        qc_id: '',
        qc_type: '',
        qc_name: '',
        qc_genid: '',
        qc_created_by: '',
        qc_created_date: ''
    });
    const [UpdateStatus, setUpdateStatus] = useState(false);
    const [ProductData, setProductData] = useState({});
    const [ItemExisted, setItemExisted] = useState(0);
    const [NowPrice, setNowPrice] = useState({
        TotalPrice: 0.00
    });

    const MySwal = withReactContent(Swal);
    const SwalShow = (status, message) => {
        if(status == true){
            MySwal.fire({
                title: 'สำเร็จ',
                text: message,
                icon: 'success'
            })
        } else{
            MySwal.fire({
                title: 'ไม่สำเร็จ',
                text: message,
                icon: 'error'
            })
        }
    };

    const handleSetProductData = (Data) => {
        setProductData(Data);
        InsertProductToQuotation(Data);
    };

    const handleSetUpdateStatus = (status) => {
        setUpdateStatus(status);
    };


    const CheckQuotationExisted = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/QuotationCheckExist/${QuotationReference}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
              if(res.data.Status == true){
                setQuotationReferenceExisted(QuotationReference);
                setQuotationCreatedData(res.data.Result);
                setItemExisted(res.data.ItemExist);
              } else{
                  navigate('/sQuotationHome');
              }
        //   console.log(res);
        } catch (err) {
          console.error('Error fetching bill history', err);
        //   navigate('/sPosHome');
        }
    };

    const InsertProductToQuotation = async (Data) => {
        if(ItemExisted >= 15){
            SwalShow(false, 'สินค้าห้ามเกิน 15 รายการ')
            return;
        } else{
            Data = {
                qd_item_name: Data.pro_name,
                qd_quo_ref: QuotationReferenceExisted,
                qd_item_unit: Data.pro_unit,
                qd_item_price: Data.pro_priceA,
                qd_item_quantity: 1,
                qd_item_barcode: Data.pro_barcode
            };
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/QuotationProductInsert`, Data, {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    },
                });
                console.log(res);
                setUpdateStatus(true);
            } catch (err) {
                console.error('Error fetching bill history', err);
            //   navigate('/sPosHome');
            }
        }
        
    };

    // const GetProductFromQuotation = async () => {
    //     try {
    //         const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/QuotationProductHistory/${QuotationReference}`, {
    //             headers: {
    //                 "Access-Control-Allow-Origin": "*",
    //                 "Content-Type": "application/json",
    //             },
    //         });
    //         if(res.data.Status == true){
    //             setNowPrice({['TotalPrice']: res.data.TotalPrice});
    //         }
    //     } catch (err) {
    //         console.error('Error fetching bill history', err);
    //     //   navigate('/sPosHome');
    //     }
    // };

    

    const increment = useCallback(() => {
        CheckQuotationExisted();
        if(UpdateStatus == true){
            // GetProductFromQuotation();
            setUpdateStatus(false);
        }
    
    }, [QuotationReference, UpdateStatus, ItemExisted])

    useEffect(() => {
        CheckQuotationExisted();
        // GetProductFromQuotation();
        if(UpdateStatus == true){
            // GetProductFromQuotation();
            setUpdateStatus(false);
            increment();
        }
        console.log(ItemExisted);
    
    }, [QuotationReference, ItemExisted, UpdateStatus])


    const handleSetNowPrice = (Data) => {
        setNowPrice({['TotalPrice']: Data});
    }

    // const UpdateDetailCustomer = () => {

    // }


    
    
    

  return (
    <>
        <NavBar/>
        <div className="container-fluid" onClick={increment}>
            <div className="row mt-3">
                <div className="col-md-4">
                    <PosSearchProductList onClick={() => handleSetUpdateStatus(true)} ProductData={handleSetProductData} UpdateStatus={UpdateStatus}/>
                </div>
                <div className="col-md-8">
                    {
                        ItemExisted > 0 ? (
                            <div className="row">
                                <div className="col-md-12">
                                    <Link className='btn btn-warning col-12' to={`/PrintQuotation/${QuotationReference}`} target="_blank">
                                       <i className="bi bi-printer" /> พิมพ์รายการ
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-md-12">
                                    <button className='btn btn-primary col-12'>
                                        <i className="bi bi-hand-index" /> กรุณาเลือกผลิตภัณฑ์
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-body pt-4 card-colorsmk-blink-nothover">
                                <h1 align="center" className="smktitle h1-oversize">
                                    {
                                        NowPrice.TotalPrice > 0 ? (
                                            <>
                                                {parseFloat(NowPrice.TotalPrice).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </>
                                        ) : (
                                            <>
                                                0.00
                                            </>
                                        )
                                    }
                                </h1>
                            </div>
                        </div>
                        <div className="col-md-12 mt-3">
                            {/* <div className="card"> */}
                                <div className="row">
                                    <div className="col-11">
                                        <div className="form-control form-control-lg">
                                            <h4 className="card-title text-center">
                                                ใบเสนอราคาของ : {ShortText(QuotationCreatedData.qc_name, 25)} - ({QuotationCreatedData.qc_type})
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <QuotationUpdateModal UpdateStatus={handleSetUpdateStatus} QuotationCreatedData={QuotationCreatedData}/>
                                    </div>
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                    <QuotationShowProductList NowPrice={handleSetNowPrice} QuotationReference={QuotationReference} UpdateStatus={UpdateStatus} handleUpdateStatus={handleSetUpdateStatus}/>
                </div>
            </div>
        </div>
    </>
  )
}

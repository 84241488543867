import React,{useCallback, useEffect, useState, useRef} from 'react';
import NavBar from './NavBar';
import uniqid from 'uniqid';
import axios from 'axios';
import SearchContactList from '../Function/ContactList';
import { useNavigate } from 'react-router-dom';
import ShortText from '../Function/Text';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';


function QuotationHome() {
    const Navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const [ContactData, setContactData] = useState({});
    const [UpdateStatus, setUpdateStatus] = useState(false);
    const [formData, setFormData] = useState({
        qc_type: 'ลูกค้าทั่วไป',
        qc_name: '',
        qc_genid: uniqid("qc-"),
        qc_created_by: ''
    });
    const [QuotationHistoryList, setQuotationHistoryList] = useState([]);


    
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validate = () => {
        let formErrors = {};

        if (!formData.qc_type) {
            formErrors.qc_type = 'กรุณาเลือกประเภทลูกค้า';
        }
        if (!formData.qc_name) {
            formErrors.qc_name = 'กรุณากรอกชื่อลูกค้า';
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        if(!validate()){
            return;
        }

        if(Object.entries(errors).length == 0){
            try{
                const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/QuotationCreate`,
                    formData,
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                        },
                    }
                );
                if(response.data.Status == true){
                    console.log(response);
                    Navigate('/sQuotation/'+formData.qc_genid);
                }

            } catch(e){
                console.error(e);
            }
        }
        
    };

    const CheckQuotationHistory = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/QuotationHistory`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
              if(res.data.Status == true){
                setQuotationHistoryList(res.data.Result);
              } 
              console.log(res.data.Result);
        //   console.log(res);
        } catch (err) {
          console.error('Error fetching bill history', err);
        //   navigate('/sPosHome');
        }
    };

    useEffect(() => {
        const checkAuthen = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                const response = await axios({
                    url: `${process.env.REACT_APP_API_SERVER}/getUserInformation`,
                    method: "POST",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    },
                });
                    setFormData((prev) => ({
                        ...prev,
                        ['qc_created_by']: response.data.user.member_genid
                    }));
                    console.log(response.data.user);
                } catch (err) {
                    console.error('Error checking session', err);
                }
            } 
        };

    checkAuthen();
    CheckQuotationHistory();
    }, []);

    const handleSetContactData = (Data) => {
        setContactData(Data);
        setFormData((prev) => ({
            ...prev,
            ['qc_type']: Data.contact_type,
            ['qc_name']: Data.contact_name
        }));
    };

    const scrollRef = useRef(null);

    const handleScroll = () => {
        if (scrollRef.current) {
        console.log("Scroll Top:", scrollRef.current.scrollTop);
        }
    };

    

    return (
        <>
            <NavBar/>
            <div className="container-fluid vh-100">
                <div className="row mt-3">
                    <div className="col-md-4">
                        <SearchContactList ContactData={handleSetContactData} UpdateStatus={UpdateStatus}/>
                    </div>
                    <div className="col-md-6">
                        <div className="">
                            <button className="btn btn-primary col-12">กรุณาเลือกรายการลูกค้า</button>
                            <form onSubmit={handleSubmit}>
                                <div className="row mt-4">
                                    <div className='col-md-6'>
                                        <label>ประเภทลูกค้า</label>
                                        <select name="qc_type" value={formData.qc_type} onChange={handleChange} className='form-select'>
                                            <option value="ลูกค้าทั่วไป">ลูกค้าทั่วไป</option>
                                            <option value="ร้านค้าพันธมิตร">ร้านค้าพันธมิตร</option>
                                            <option value="โรงเรียน">โรงเรียน</option>
                                            <option value="หน่วยงานราชการ">หน่วยงานราชการ</option>
                                            <option value="หน่วยงานอิสระ">หน่วยงานอิสระ</option>
                                        </select>
                                        {errors.qc_type && <span style={{ color: 'red' }}>{errors.qc_type}</span>}
                                    </div>

                                    <div className='col-md-6'>
                                        <label>ชื่อลูกค้า</label>
                                        <input type="text" name="qc_name" value={formData.qc_name} onChange={handleChange} className='form-control'/>
                                        {errors.qc_name && <span style={{ color: 'red' }}>{errors.qc_name}</span>}
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className='col-md-6'>
                                        <label>GenID</label>
                                        <input type="text" name="qc_genid" value={formData.qc_genid} readOnly className='form-control'/>
                                    </div>

                                    <div className='col-md-6'>
                                        <label>สร้างโดย</label>
                                        <input type="text" name="qc_created_by" value={formData.qc_created_by} readOnly className='form-control'/>
                                        {errors.qc_created_by && <span style={{ color: 'red' }}>{errors.qc_created_by}</span>}
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-12">
                                        <button type="submit" className='btn btn-secondary col-12'>สร้างใบเสนอราคา</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                    <div className="col-md-2">
                        {
                            Object.keys(QuotationHistoryList).length > 0 ? (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="btn btn-outline-secondary mb-3 col-12">
                                            ประวัติการสร้างรายการ
                                        </div>
                                        <div className="row scroll-box-with-hide-bar" onScroll={handleScroll} ref={scrollRef}>
                                            {
                                                QuotationHistoryList.map(list => (
                                                    <div className="col-md-12">
                                                        <div className="col-12">
                                                            <button onClick={() => Navigate(`/sQuotation/${list.qc_genid}`)} key={list.id} className="btn btn-outline-secondary mb-2 col-12 text-start">
                                                                <div className="h6 d-inline">
                                                                    <i className="bi bi-file-earmark-person"/> {ShortText(list.qc_name, 25)}
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <label className="text-danger">
                                            *** scroll เพื่อหารายการเก่า ***
                                        </label>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuotationHome
export default function BarcodeGen(BarcodeNumber) {
    // Ensure the input is treated as a string
    BarcodeNumber = BarcodeNumber.toString();

    // Determine the number of zeros to prepend
    let zerosNeeded = 4 - BarcodeNumber.length;

    // Generate the zeros string
    let zeros = '0'.repeat(zerosNeeded);

    // Concatenate and return the result
    return "s" + zeros + BarcodeNumber;
}
import {React, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer } from '@react-pdf/renderer';
import axios from 'axios';
import moment from 'moment';
import SarabunRegular from '../Sarabun/Sarabun-Regular.ttf';
import SarabunBold from '../Sarabun/Sarabun-Bold.ttf';
import PrintQuotationDocument from '../Components/PrintQuotationDocs';

  const PrintQuotation = () => {
    const {QuotationReference} = useParams();
    const [QuotationData, setQuotationData] = useState({
    });
    const [ProcessingUserInformation, setProcessingUserInformation] = useState({});
    const [NowPrice, setNowPrice] = useState({
        "TotalPrice": 0.00
    });
    const [AllItemBag, setAllItemBag] = useState([]);
    const [AllItemCount, setAllItemCount] = useState(0);
    const [Admin, setAdmin] = useState({});
    const [QRCodeImg, setQRCodeImg] = useState("");

    useEffect(() => {
      showHistory();
      showItemBag();
      RequestAdmin();
      // QRCode();
    }, [])
    

    const showHistory = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/QuotationCheckExist/${QuotationReference}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
            if(res.data.Status == true){
                setQuotationData(res.data.Result);
                setAllItemCount(res.data.ItemExist);
                console.log(res.data.Result, res.data.ItemExist);
            } 
        console.log(res);
        } catch (err) {
            console.error('Error fetching bill history', err);
        // window.location = '/sPosHome';
        }
    };

    const showItemBag = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/QuotationProductHistory/${QuotationReference}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
          if(res.data.Status == true){
              setAllItemBag(res.data.Result);
              setNowPrice({['TotalPrice']: res.data.TotalPrice});
          } 
          console.log(res);
      } catch (err) {
        console.error('Error fetching bill history', err);
      }
    };

    const RequestAdmin = () => {
      const token = localStorage.getItem('token');
      if(token != null){
        axios({
          url: `${process.env.REACT_APP_API_SERVER}/authen`,
          method: "POST",
          headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "Authorization": "Bearer "+ token
          },
        })
        .then((res) => {
            // console.log(res.data.decoded);
            setAdmin(res.data.decoded);
        })
        .catch((err) => {
          console.error('Error checking session', err);
        })
      }
    }



    return (
      <>
      <div style={{ height: '100vh' }}>
        <PDFViewer style={{ width: '100%', height: '100%' }}>
          <PrintQuotationDocument Admin={Admin} QuotationReference={QuotationReference} AllItemBag={AllItemBag} QuotationData={QuotationData} NowPrice={NowPrice} CountItemAmountAll={AllItemBag.reduce((sum, item) => sum + parseFloat(item.qd_item_quantity), 0)} />
        </PDFViewer>
      </div>
      </>
    );
  };

  export default PrintQuotation;

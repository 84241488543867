import React from 'react'

function ContactUs() {
  return (
    <>
        <div className="bg-res2 vh-100" style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/Background/bg.jpeg)`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top center',
            backgroundAttachment: 'fixed'
        }} >
            <div className="container-fluid pt-3">
                <div className="row" align="center">
                    <div className="col-5" />
                    <div className="col-md-2">
                        <img className='cursor-pointer logo-smk img-fluid' style={{Width: '100px'}} src={process.env.PUBLIC_URL + "/assets/smkconcretelogo.png"}/>
                    </div>
                    <div className="col-5" />
                    <h3 className="text-center mgt-1 logo-smk">ติดต่อเรา</h3>
                    {/* <a class="btn btn-outline-danger col-md-12 mgt-1" href="">
                            <i class="bi bi-bag"></i> สั่งสินค้าตามใบเสนอราคา
                        </a> */}
                </div>
                <div className="row mt-3">
                    <div className="col-4" />
                    <div className="col-md-4">
                        <a className="btn btn-light col-12 mt-3" href="tel:077365401">
                            <i className="bi bi-headset" /> โทรศัพท์ฝ่ายขาย (เว้นวันอาทิตย์)
                        </a>
                        <a className="btn btn-light col-12 mt-3" href="tel:0857841509">
                            <i className="bi bi-telephone" /> โทรศัพท์ (ทุกวัน)
                        </a>
                        <a className="btn btn-light col-12 mt-3" href="https://www.facebook.com/somsakshop.ltd/">
                            <i className="bi bi-facebook" /> เฟซบุ๊คแฟนเพจ
                        </a>
                        <a className="btn btn-light col-12 mt-3" href="https://m.me/somsakshop.ltd/">
                            <i className="bi bi-messenger" /> แชตกับเรา
                        </a>
                        <a className="btn btn-light col-12 mt-3" href="https://lin.ee/VJ49xc5">
                            <i className="bi bi-line" /> ไลน์ฝ่ายขาย
                        </a>
                        <a className="btn btn-light col-12 mt-3" href="https://goo.gl/maps/NF23ccZwgtPDSdDs7">
                            <i className="bi bi-geo-alt" /> แผนที่
                        </a>
                        <a className="btn btn-light col-12 mt-3" href={`https://m.me/somsakshop.ltd?message=%E0%B8%82%E0%B8%AD%E0%B9%83%E0%B8%9A%E0%B9%80%E0%B8%AA%E0%B8%99%E0%B8%AD%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2`}>
                            <i className="bi bi-file-earmark-bar-graph"/> ขอใบเสนอราคา
                        </a>
                    </div>
                    <div className="col-4" />
                </div>
            </div>

        </div>
        

    </>
  )
}

export default ContactUs
// src/ProductValidation.js
const ProductValidate = (name, value) => {
    switch (name) {
      case 'pro_name':
        if (!value) {
          return 'Product name is required';
        }
        break;
      case 'pro_barcode':
        if (!value) {
          return 'Barcode is required';
        } else if (value.length < 2) {
          return 'Barcode must be than 2 digits';
        }
        break;
      case 'pro_category':
        if (!value) {
          return 'Category is required';
        }
        break;
      case 'pro_priceA':
      case 'pro_priceB':
      case 'pro_priceC':
        if (value === '' || isNaN(value) || Number(value) <= 0) {
          return 'Price must be a positive number';
        }
        break;
      case 'pro_unit':
        if (!value) {
          return 'Unit is required';
        }
        break;
      case 'pro_date':
        if (!value) {
          return 'Date is required';
        }
        break;
      case 'pro_barstatus':
        if (value === '') {
          return 'Barcode status must be a number';
        }
        break;
      case 'pro_nowstock':
        if (value === '' || isNaN(value) || Number(value) < 0) {
          return 'Stock must be a non-negative number';
        }
        break;
      case 'pro_genid':
        if (!value) {
          return 'Generated ID is required';
        }
        break;
      default:
        break;
    }
    return '';
  };
  
  export default ProductValidate;
  
import React, {useState} from 'react';
import axios from 'axios';
// import ShortText from '../Function/Text';
import NavBar from './NavBar';
import { Button } from 'react-bootstrap';
import SalesHistoryExport from '../Function/SalesHistoryExport';

function SalesHistory() {


  return (
    <>
        <NavBar/>
        <div className="bg-res2 vh-100" style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/Background/bg.jpeg)`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center',
              backgroundAttachment: 'fixed'
            }} >
          <div className="container p-5">
            <div className="mt-5 pt-2">
              <div className="row">
                <div className="col"></div>
                <div className="col-md-6 d-flex justify-content-end">
                  <h1 className="text-center h1-oversize poppins-font text-light mt-5 p-5">
                    <i className="bi bi-clock-history" /> Sales History<br/>
                    <h4 className='mt-2 text-end'>รายงานการขาย</h4>
                  </h1>
                  
                </div>
                <div className="col"></div>
              </div>
              <div className="row">
                <div className="col-4"></div>
                <div className="col-md-4">
                  <SalesHistoryExport/>
                </div>
                <div className="col-4"></div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default SalesHistory
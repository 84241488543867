export const validateContactForm = (contact) => {
    const errors = {};
  
    if (!contact.contact_name) errors.contact_name = 'Contact name is required';
  
    const phonePattern = /^[0-9]+$/;
    if (!contact.contact_phone) {
      errors.contact_phone = 'กรุณากรอก';
    } else if (!phonePattern.test(contact.contact_phone)) {
      errors.contact_phone = 'กรุณากรอกตัวเลข 0 - 9 เท่านั้น';
    } else if (contact.contact_phone.length < 9) {
        errors.contact_phone = 'หมายเลขโทรศัพท์ไม่ถูกต้อง';
    }
  
    if (!phonePattern.test(contact.contact_tel) && (contact.contact_tel.length > 0)) {
      errors.contact_tel = 'กรุณากรอกตัวเลข 0 - 9 เท่านั้น';
    } else if (contact.contact_tel.length < 9 && (contact.contact_tel.length > 0)) {
      errors.contact_tel = 'หมายเลขโทรศัพท์ไม่ถูกต้อง';
    }
  
    if (!contact.contact_type) errors.contact_type = 'กรุณาเลือกปรเภท';
  
    return errors;
  };
  
import React, { useEffect, useState } from 'react';

export default function Logout() {
    useEffect(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('expire');
        setTimeout(() => {
            window.location = "/Login";
        }, 500);
    }, [])
    
  return (
    <div>Redirecting to Login</div>
  )
}

// ContactForm.js

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { validateContactForm } from '../Function/ContactValidation';
import uniqid from 'uniqid';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const UpdateContact = ({ ContactData, UpdateStatus }) => {
    const MySwal = withReactContent(Swal);
    const [contact, setContact] = useState({
        contact_name: '',
        contact_phone: '',
        contact_tel: '',
        contact_email: '',
        contact_taxid: '',
        contact_type: '',
        contact_address: '',
        contact_genid: ''
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        setContact({
            ...contact,
            ...{
                contact_name: ContactData.contact_name || '',
                contact_phone: ContactData.contact_phone || '',
                contact_tel: ContactData.contact_tel || '',
                contact_email: ContactData.contact_email || '',
                contact_taxid: ContactData.contact_taxid || '',
                contact_type: ContactData.contact_type || '',
                contact_address: ContactData.contact_address || '',
                contact_genid: ContactData.contact_genid || ''
            }
        });
    }, [ContactData]);

    const handleChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateContactForm(contact);
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length > 0){
            return;
        } else{
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/UpdateContactData`, contact);
                console.log('Contact added:', response.data);
                // Reset form after submission
                if(response.data.Status == true){
                  SwalShow(response.data.Status, response.data.message);
                    setContact({
                        contact_name: '',
                        contact_phone: '',
                        contact_tel: '',
                        contact_email: '',
                        contact_taxid: '',
                        contact_type: '',
                        contact_address: '',
                        contact_genid: ''
                    });
                    UpdateStatus(true);
                    setErrors({});
                    increment();
          
                } else{
                  SwalShow(response.data.Status, response.data.message);
                }
                
              } catch (error) {
                console.error('There was an error adding the product!', error);
              }
        }

        
    };

    const DeleteContactData = async(ContactGenID) => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/DeleteContactData/${ContactGenID}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
            if(res.status == 200){
                if(res.data.Status == true){
                    SwalShow(res.data.Status, res.data.message);
                    setContact({
                        contact_name: '',
                        contact_phone: '',
                        contact_tel: '',
                        contact_email: '',
                        contact_taxid: '',
                        contact_type: '',
                        contact_address: '',
                    });
                    UpdateStatus(true);
                    setErrors({});
                    increment();
                }
          }
        } catch (err) {
          console.error('Error fetching bill history', err);
        }
      }
      

    const SwalShow = (status, message) => {
        if(status == true){
        MySwal.fire({
            title: 'สำเร็จ',
            text: message,
            icon: 'success'
        })

        } else{
        MySwal.fire({
            title: 'ไม่สำเร็จ',
            text: message,
            icon: 'error'
        })
        }
    };

    const SwalSubmit = (ContactGenID, ContactName) => {
        MySwal.fire({
            title: 'ลบหรือไม่',
            text: `ยืนยันที่จะลบ #${ContactName} หรือไม่ ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteContactData(ContactGenID);
            }
        })
        
    };

    const handleKeyNumberDigitsCheck = (e) => {
        const key = e.key;
    
        // Allow numeric keys, backspace, and delete
        if (!/[0-9]/.test(key) && key !== 'Backspace' && key !== 'Delete') {
          e.preventDefault();
        }
    };

    const increment = useCallback(() => {
        
    }, [contact]);

  return (
    <form onSubmit={handleSubmit}>
        <div className="row">
            <div className='col-md-6'>
                <label>GenID</label>
                <input type="text" name="contact_taxid" value={contact.contact_genid} onChange={handleChange} required disabled={true} className='form-control'/>
                {errors.contact_taxid && <span className='text-danger'>{errors.contact_taxid}</span>}
            </div>
            <div className='col-md-6'>
                <label>ประเภทลูกค้า</label>
                <select name="contact_type" value={contact.contact_type} onChange={handleChange} required className='form-select'>
                    <option value="ลูกค้าทั่วไป">ลูกค้าทั่วไป</option>
                    <option value="ร้านค้าพันธมิตร">ร้านค้าพันธมิตร</option>
                    <option value="โรงเรียน">โรงเรียน</option>
                    <option value="หน่วยงานราชการ">หน่วยงานราชการ</option>
                    <option value="หน่วยงานอิสระ">หน่วยงานอิสระ</option>
                </select>
                {errors.contact_type && <span className='text-danger'>{errors.contact_type}</span>}
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-md-6">
                <label>ชื่อติดต่อ</label>
                <input type="text" name="contact_name" value={contact.contact_name} onChange={handleChange} required className='form-control'/>
                {errors.contact_name && <span className='text-danger'>{errors.contact_name}</span>}
            </div>
            <div className="col-md-6">
                <label>อีเมล</label>
                <input type="email" name="contact_email" value={contact.contact_email} onChange={handleChange} className='form-control'/>
                {errors.contact_email && <span className='text-danger'>{errors.contact_email}</span>}
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-md-4">
                <label>หมายเลขโทรศัพท์</label>
                <input type="text" name="contact_phone" onKeyDown={handleKeyNumberDigitsCheck} value={contact.contact_phone} maxLength={10} onChange={handleChange} required className='form-control'
                />
                {errors.contact_phone && <span className='text-danger'>{errors.contact_phone}</span>}
            </div>
            <div className="col-md-4">
                <label>หมายเลขเพิ่มเติม</label>
                <input type="text" name="contact_tel" onKeyDown={handleKeyNumberDigitsCheck} value={contact.contact_tel} maxLength={10} onChange={handleChange} className='form-control'/>
                {errors.contact_tel && <span className='text-danger'>{errors.contact_tel}</span>}
            </div>
            <div className='col-md-4'>
                <label>เลขที่ผู้เสียภาษี</label>
                <input type="text" name="contact_taxid" onKeyDown={handleKeyNumberDigitsCheck} maxLength={13} value={contact.contact_taxid} onChange={handleChange} className='form-control'/>
                {errors.contact_taxid && <span className='text-danger'>{errors.contact_taxid}</span>}
            </div>
        </div>
        <div className="row mt-3">
            <div className='col-md-12'>
                <label>ที่อยู่ลูกค้า</label>
                <textarea name="contact_address" value={contact.contact_address} onChange={handleChange} className='form-control'/>
                {errors.contact_address && <span className='text-danger'>{errors.contact_address}</span>}
            </div>
        </div>
        <div className="row">
            <div className='col-md-6 mt-4'>
                <button className='btn btn-secondary col-12' type="submit">เปลี่ยนแปลงข้อมูลรายการติดต่อ</button>
            </div>
            <div className='col-md-6 mt-4'>
                {
                    contact.contact_genid && contact.contact_name && contact.contact_genid != null && contact.contact_name != null ? (
                        <button className='btn btn-danger col-12' onClick={() => SwalSubmit(contact.contact_genid, contact.contact_name)} type="button">ลบรายการติดต่อ</button>
                    ) : (
                        <button className='btn btn-danger col-12' disabled={true} type="button">ลบรายการติดต่อ</button>
                    )
                }
            </div>
        </div>
    </form>
  );
};

export default UpdateContact;

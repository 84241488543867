import React, {useCallback, useState, useEffect} from 'react';
import ShortText from '../Function/Text';
import axios from 'axios';

function QuotationShowProductList({QuotationReference, UpdateStatus, handleUpdateStatus, NowPrice}) {
    const [AddedProduct, setAddedProduct] = useState([]);
    const [Loading, setLoading] = useState(false);
    const LimitKeyInput = (event) => {
        const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', '.'];
        const isNumberKey = event.key >= '0' && event.key <= '9';
    
        if (!allowedKeys.includes(event.key) && !isNumberKey) {
            event.preventDefault();
        }
    };
    const GetProductFromQuotation = async () => {
        // setLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/QuotationProductHistory/${QuotationReference}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            });
            if(res.data.Status == true){
                setAddedProduct(res.data.Result);
                NowPrice(res.data.TotalPrice);
                setLoading(false);
            }
        } catch (err) {
            console.error('Error fetching bill history', err);
        //   navigate('/sPosHome');
        }
    };

    const UpdateProductDataInQuotation = async (Price, Quantity, QuotationItemReference) => {
        if(Quantity == 0 || Quantity == undefined || Quantity == null){
            Quantity = 1;
        }
        if(Price < 1){
            return;
        }
        var Data = {
            qd_quo_ref: QuotationReference,
            qd_item_ref: QuotationItemReference,
            qd_item_price: Price,
            qd_item_quantity: Quantity
        };
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/QuotationProductUpdate`, Data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            });
            if(res.data.Status == true){
                handleUpdateStatus(true);
                GetProductFromQuotation();
            }
        console.log(Data);
        } catch (err) {
            console.error('Error fetching bill history', err);
        //   navigate('/sPosHome');
        }
    };

    const DeleteProductDataInQuotation = async (QuotationItemReference) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/QuotationProductDelete/${QuotationItemReference}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            });
            if(res.data.Status == true){
                setLoading(true);
                handleUpdateStatus(true);
                increment();
            }
            console.log(res.data.Status);
        } catch (err) {
            console.error('Error fetching bill history', err);
        //   navigate('/sPosHome');
        }
    };

    useEffect(() => {
      if(UpdateStatus == true){
        GetProductFromQuotation();
      } else{
        GetProductFromQuotation();
      }
    
    }, [UpdateStatus]);

    const increment = useCallback(() => {
        if(UpdateStatus == true){
          GetProductFromQuotation();
        } else{
          GetProductFromQuotation();
        }
      
      }, [UpdateStatus, AddedProduct]);
    
    if (Object.keys(AddedProduct).length > 0 || Loading) {
        if(Loading){
            return (<></>)
        }
        return (
            <div className="row" onClick={increment}>
                {
                    AddedProduct.map((list) => (
                        <div className="col-6">
                            <div className="card" key={list.qc_item_id}>
                                <div className="card-body">
                                    <h5 className="text-start">
                                        {
                                            ShortText(list.qd_item_name, 30)
                                        }
                                    </h5>
                                    <h6 className="text-secondary">
                                        #{list.qd_item_barcode}
                                    </h6>
                                    <>
                                        <div className="row">
                                            <div className="col-md-6 text-start">
                                                <label htmlFor="">
                                                    <small>จำนวน ({list.qd_item_unit})</small>
                                                </label>
                                                <input type="text" className="form-control text-center" defaultValue={list.qd_item_quantity} onChange={(e) => UpdateProductDataInQuotation(list.qd_item_price, e.target.value, list.qd_item_ref)} onKeyDown={LimitKeyInput} />
                                            </div>
                                            <div className="col-md-6 text-start">
                                                <label htmlFor="">
                                                    <small>ราคาต่อหน่วย</small>
                                                </label>
                                                <input type="text" className="form-control text-center" defaultValue={list.qd_item_price} onChange={(e) => UpdateProductDataInQuotation(e.target.value, list.qd_item_quantity, list.qd_item_ref)} onKeyDown={LimitKeyInput} />
                                            </div>
                                        </div>
                                    </>
                                    <div className="col-md-12 mt-3">
                                        <button className="btn btn-outline-danger col-md-12" onClick={() => DeleteProductDataInQuotation(list.qd_item_ref)}>
                                            <i className="bi bi-trash"/> ลบรายการ
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default QuotationShowProductList
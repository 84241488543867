import { useState, useRef, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import BillNumber from '../../Function/BillNumber';
import ShortText from '../../Function/Text';

function QuotationUpdateModal({QuotationCreatedData, UpdateStatus}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [AllowUpdateButton, setAllowUpdateButton] = useState(false);
    const [CustomerData, setCustomerData] = useState({
        qc_name: '',
        qc_type: ''
    });

    const handleCustomerName = (e) => {
        e.preventDefault();
        setCustomerData(prev => ({...prev, ['qc_name']: e.target.value}));
        if(e.target.value.length > 0){
            setAllowUpdateButton(true);
        } else{
            setAllowUpdateButton(false);
        }
        console.log(CustomerData.qc_name);
    }

    const handleChange = (e) => {
        e.preventDefault();
        setCustomerData(prev => ({...prev, ['qc_type']: e.target.value}));
        if(CustomerData.qc_type != "" || CustomerData.qc_type != null){
            setAllowUpdateButton(true);
        } else{
            setAllowUpdateButton(false);
        }
        console.log(CustomerData.qc_type);
    }

    const handleSubmitUpdate = async (QuotationReference) => {
        if(AllowUpdateButton){
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/UpdateQuotationData/${QuotationReference}`, CustomerData, {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                  },
                });
                  if(res.data.Status == true){
                    UpdateStatus(true);
                    handleClose();
                  }
            } catch (err) {
                console.error('Error fetching bill history', err);
            }
        } else{
            return;
        }
    }

    useEffect(() => {
        setCustomerData(prev => ({
            ...prev, 
            qc_name: QuotationCreatedData.qc_name,
            qc_type: QuotationCreatedData.qc_type
        }));
    }, [QuotationCreatedData.qc_name, QuotationCreatedData.qc_type]);
    

    



    return (
        <>
            <button className="btn btn-lg btn-primary col-12" type="button" onClick={handleShow}>
                <i className="bi bi-pencil" />
            </button>
            <Modal centered size="lg" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>เปลี่ยนแปลงข้อมูลลูกค้า</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-3">
                            <label htmlFor="">ประเภทลูกค้า</label>
                            <select name="qc_type" value={CustomerData.qc_type} onChange={e => handleChange(e)} className='form-select'>
                                <option value="ลูกค้าทั่วไป">ลูกค้าทั่วไป</option>
                                <option value="ร้านค้าพันธมิตร">ร้านค้าพันธมิตร</option>
                                <option value="โรงเรียน">โรงเรียน</option>
                                <option value="หน่วยงานราชการ">หน่วยงานราชการ</option>
                                <option value="หน่วยงานอิสระ">หน่วยงานอิสระ</option>
                            </select>
                        </div>
                        <div className="col-9">
                            <label htmlFor="">ชื่อลูกค้า</label>
                            <input type="text" className="form-control" value={CustomerData.qc_name} onChange={e => handleCustomerName(e)}/>
                        </div>
                    </div>
                </Modal.Body>

                <div className="modal-footer" style={{justifyContent: 'center', display: 'block'}}>
                    <div className="row">
                        <div className="col-6">
                            <button type="button" onClick={handleClose} className="btn btn-outline-secondary col-12">ยกเลิก</button>
                        </div>
                        <div className="col-6">
                            {
                                AllowUpdateButton ? (
                                    <button type="submit" onClick={() => handleSubmitUpdate(QuotationCreatedData.qc_genid)} className="btn btn-secondary col-12">แก้ไขรายละเอียด</button>
                                ) : (
                                    <button type="submit" disabled={true} data-bs-dismiss="modal" name="create" className="btn btn-secondary col-12">แก้ไขรายละเอียด</button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default QuotationUpdateModal;
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import Register from './Components/Register'
import Login from './Components/Login'
import Home from './Components/Home'
import axios from 'axios';
import Logout from './Components/Logout';
import PosHome from './Components/PosHome';
import Pos from './Components/Pos';
import PrintReceipt from './Function/PrintReceipt'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom';
import PosProduct from './Components/PosProduct';
import ContactService from './Components/ContactService';
import BarcodePrinting from './Components/BarcodePrinting';
import QuotationHome from './Components/QuotationHome';
import Quotation from './Components/Quotation';
import PrintQuotation from './Function/PrintQuotation';
import NotFound from './Components/NotFound';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import RedirectURL from './Components/RedirectURL';
import SalesHistory from './Components/SalesHistory'
import ContactUs from './Components/ContactUs';



export default function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const currentTime = Date.now() / 1000;
  const checkAuth = async () => {
    const token = localStorage.getItem('token');
    if (token != null) {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_SERVER}/authen`,
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        });
        if(response.status == 200){
          setLoggedIn(response.data.statusEntry);
          localStorage.setItem('expire', response.data.decoded.exp);
          localStorage.setItem('usersecretid', encodeURIComponent(base64_encode(response.data.decoded.user_genid)));
          if(localStorage.getItem('expire') < currentTime){
            localStorage.removeItem('token');
            localStorage.removeItem('expire');
            localStorage.removeItem('usersecretid');
          }
        } else{
          setLoggedIn(false);
        }
      } catch (err) {
        console.error('Error checking session', err);
        // localStorage.removeItem('token');
      }
    } else {
      setLoggedIn(false);
    }

    // if(localStorage.setItem('expire') < currentTime){
    //   localStorage.removeItem('token');
    //   localStorage.removeItem('expire');
    // }

  };

  useEffect(() => {
    checkAuth();
  }, []);

  

  // const ProtectedRoute = ({ element, loggedIn }) => {
  //   return loggedIn ? element : <Navigate to="/Login" />;
  // };
  const ProtectedRoute = ({ element }) => {
    var LOGIN_VERIFICATION = false;
    const currentTime = Date.now() / 1000;
    if(localStorage.getItem('expire') > currentTime){
      LOGIN_VERIFICATION = true;
      setLoggedIn(true);
    } else {
      LOGIN_VERIFICATION = false;
      setLoggedIn(false);
    }
    return LOGIN_VERIFICATION ? element : <Navigate to="/Login" />;
  };

  const CurrentLocation = useLocation().pathname;

  
  let TitleLabel = 'SMKCONNECTPLUS+ Professional Edition';

  if (CurrentLocation == '/Home' || CurrentLocation == '/') {
    TitleLabel = 'หน้าแรก | '+TitleLabel;
  } else if (CurrentLocation == '/sPosHome' || !CurrentLocation.search('/sPos/')) {
    TitleLabel = 'sPos | '+TitleLabel;
  } else if (CurrentLocation == '/BarcodePrinting') {
    TitleLabel = 'พิมพ์บาร์โค้ด | '+TitleLabel;
  } else if (CurrentLocation == '/Login') {
    TitleLabel = 'เข้าสู่ระบบ | '+TitleLabel;
  } else if (CurrentLocation == '/Register') {
    TitleLabel = 'ลงทะเบียน | '+TitleLabel;
  } else if (CurrentLocation == '/PosProduct') {
    TitleLabel = 'ประวัติสินค้า | '+TitleLabel;
  } else if (CurrentLocation == '/SalesHistory'){
    TitleLabel = 'ประวัติการขาย | '+TitleLabel;
  } else if (CurrentLocation == '/PrintQuotation' || CurrentLocation == '/sQuotation' || CurrentLocation == '/sQuotationHome' || !CurrentLocation.search('/sQuotation/')) {
    TitleLabel = 'sQuotation | '+TitleLabel;
  }
  
  

  return (
    <>
      <Helmet>
        <title>
          {TitleLabel}
        </title>
      </Helmet>

      <Routes>
        <Route path="/Home" element={<ProtectedRoute element={<Home />} />} />
        <Route path='/sQuotationHome' element={<ProtectedRoute element={<QuotationHome/>}/>} />
        <Route path='/sQuotation/:QuotationReference' element={<ProtectedRoute element={<Quotation/>}/>} />
        <Route path="/PrintQuotation/:QuotationReference" element={<ProtectedRoute element={<PrintQuotation/>}/>} />
        <Route path="/sPosHome" element={<ProtectedRoute element={<PosHome />}/>} />
        <Route path="/PosProduct" element={<ProtectedRoute element={<PosProduct />}/>} />
        <Route path="/sPos/:BillReference" element={<ProtectedRoute element={<Pos />}/>} />
        <Route path="/BarcodePrinting" element={<ProtectedRoute element={<BarcodePrinting />} />} />
        <Route path="/ContactService" element={<ProtectedRoute element={<ContactService />} />} />
        <Route path="/SalesHistory" element={<ProtectedRoute element={<SalesHistory />} />} />
        <Route path="/PrintReceipt/:BillingType/:BillReference" element={<ProtectedRoute element={<PrintReceipt />} />} />
        <Route path="/Register" element={!loggedIn ? <Register /> : <Navigate to="/Home" />} />
        <Route path="/Login" element={!loggedIn ? <Login /> : <Navigate to="/Home" />} />
        <Route path="/login.php" element={!loggedIn ? <Navigate to="/Login" /> : <Navigate to="/Home" />} />
        <Route path="/" element={<Navigate to={loggedIn ? "/Home" : "/Login"} />} />
        <Route path="/ContactUs" element={<ContactUs/>} />
        <Route path="/contact-us.php" element={<Navigate to={'/ContactUs'}/>} />
        <Route path="/Logout" element={<Logout />} />
        <Route path='*' exact={true} element={<NotFound/>} />
      </Routes>
        

    </>
  )
}

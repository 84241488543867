export default function BillNumber(billnumber) {
    // Ensure the input is treated as a string
    billnumber = billnumber.toString();

    // Determine the number of zeros to prepend
    let zerosNeeded = 7 - billnumber.length;

    // Generate the zeros string
    let zeros = '0'.repeat(zerosNeeded);

    // Concatenate and return the result
    return "SMK1" + zeros + billnumber;
}


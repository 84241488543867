import { useState } from "react";
import ExcelExport from './ExcelExport';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from "axios";
import { Button } from "react-bootstrap";
import moment from 'moment';


function SalesHistoryExport() {
  const MySwal = withReactContent(Swal);
  const [FromDate, setFromDate] = useState(null);
  const [ToDate, setToDate] = useState(null);
  const [Data, setData] = useState([]);
  const [AllowDownload, setAllowDownload] = useState(false);
  var BillData = [];

  const SubmitRequest = async() => {
    if (FromDate == '' || ToDate == '' || moment(ToDate).format('YYYY-MM-DD') < moment(FromDate).format('YYYY-MM-DD')){
      SwalShow(false, 'กรุณาเลือกวันที่ให้ถูกต้อง');
      setAllowDownload(false);
      return;
    } else{
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/GetDataSalesHistory`,
              {
                FromDate: FromDate,
                ToDate: ToDate
              }
            );
            if(response.data.Status == true){
              let index = 0;
              Object.entries(response.data.Data).forEach(([list_key, list_val]) => {
                  Object.entries(list_val).forEach(([item, data]) => {
                      Object.entries(data.InBag).forEach(([data_key, data_val]) => {
                          BillData.push({
                              Date: moment(data.bill_date).format("YYYY-MM-DD"),
                              BillReference: item,
                              CustomerName: data.bill_name,
                              CustomerContact: `'${data.bill_contact}`,
                              ProductName: data_val.bag_item_name,
                              ProductQuantity: data_val.bag_item_amount,
                              ProductPrice: data_val.bag_item_price
                          });
                          index++; // Increment index, if needed for other operations
                      });
                  });
              });
              setData(BillData);
              setAllowDownload(true);
            } else{
                SwalShow(response.data.Status, response.data.message);
                setAllowDownload(false);
            }
            
        } catch (error) {
            console.error(error);
        }
    }
  }

  const SwalShow = (status, message) => {
    if(status == true){
    MySwal.fire({
        title: 'สำเร็จ',
        text: message,
        icon: 'success'
    })

    } else{
      MySwal.fire({
          title: 'ไม่สำเร็จ',
          text: message,
          icon: 'error'
      })
    }
  };


  return (
    <>
      <div className="row">
        <div className="col-6">
          <label className='text-light'>จากวันที่</label>
          <DatePicker selected={FromDate} dateFormat="yyyy-MM-dd" placeholderText="Select a FromDate" onChange={(date) => setFromDate(date)} className="form-control form-control-transparent" />
        </div>
        <div className="col-6">
          <label className='text-light'>ถึงวันที่</label>
          <DatePicker selected={ToDate} dateFormat="yyyy-MM-dd" placeholderText="Select a ToDate" onChange={(date) => setToDate(date)} className="form-control form-control-transparent" />
        </div>
        <div className="col-md-12 mt-3">
          <Button className="col-12" variant="outline-light" onClick={SubmitRequest}>
            ยืนยันข้อมูล
          </Button>
        </div>
      </div>
      
      {
        AllowDownload == true ? (
          <ExcelExport data={Data} fileName={`[${moment(FromDate).format('YYYY-MM-DD')}] - [${moment(ToDate).format('YYYY-MM-DD')}]`} />
        ) : null
      }
    </>
  );
}

export default SalesHistoryExport;
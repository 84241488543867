import React, { useEffect } from 'react';
import axios from 'axios';

export default function Authen() {

  useEffect(() => {
    const checkAuthen = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios({
            url: `${process.env.REACT_APP_API_SERVER}/authen`,
            method: "POST",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "Authorization": "Bearer " + token
            },
          });
          console.log(response);
        } catch (err) {
          console.error('Error checking session', err);
          window.location = '/';
        }
      } else {
        window.location = '/';
      }
    };

    checkAuthen();
  }, []);

  return <div>Checking authentication...</div>;
}

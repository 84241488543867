// src/PosProductForm.js
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import ProductValidate from '../Function/ProductValidation';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const InsertBarcodePrinting = ({ProductData, UpdateStatus}) => {
    const MySwal = withReactContent(Swal);
    const SwalShow = (status, message) => {
        if(status == true){
            MySwal.fire({
                title: 'สำเร็จ',
                text: message,
                icon: 'success'
            })
        } else{
            MySwal.fire({
                title: 'ไม่สำเร็จ',
                text: message,
                icon: 'error'
            })
        }
    };
    const [formData, setFormData] = useState({
        bar_name: '',
        bar_quantity: 0,
        bar_barcode: '',
        bar_price: '0.00'
    });

    const [errors, setErrors] = useState({});


    const validate = (name, value) => {
        let errorMsg = '';
        const regex = /^[0-9]*$/;

        if (name === 'bar_quantity') {
            if (!value) {
                errorMsg = 'กรุณากรอกข้อมูล';
            } else if (isNaN(value)) {
                errorMsg = 'กรุณากรอกข้อมูลเป็นตัวเลข';
            } else if (!regex.test(value)) {
                errorMsg = 'กรุณากรอกข้อมูลเป็น 0-9 เท่านั้น';
            } else if (value <= 0) {
                errorMsg = 'กรุณากรอกข้อมูลให้ถูกต้อง';
            }
        }

        setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMsg,
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        validate(name, value);
    };

    const handleKeyDown = (e) => {
        const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
        if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
          e.preventDefault();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(formData.bar_quantity <= 0){
            SwalShow(false, 'กรุณากรอกข้อมูลให้ถูกต้อง');
            return
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/InsertBarcodePrinting`, formData);
            if(response.data.Status == true){
                SwalShow(response.data.Status, response.data.message);
                setFormData({
                    bar_name: '',
                    bar_quantity: 0,
                    bar_barcode: '',
                    bar_price: '0.00'
                });
                UpdateStatus(true);
                setErrors({});
                increment();
            } else{
                SwalShow(response.data.Status, response.data.message);
            }
        
        } catch (error) {
            console.error('There was an error adding the product!', error);
        }
    };

    useEffect(() => {
        setFormData({
            bar_name: ProductData.pro_name,
            bar_quantity: 0,
            bar_barcode: ProductData.pro_barcode,
            bar_price: ProductData.pro_priceA
        });
    }, [ProductData]);

    const increment = useCallback(() => {
        setFormData({
            bar_name: ProductData.pro_name,
            bar_quantity: 0,
            bar_barcode: ProductData.pro_barcode,
            bar_price: ProductData.pro_priceA
        });
    }, [formData]);
    

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">

        <div className="col-md-6 mb-3">
            <label>บาร์โค้ด</label>
            <input type="text" className='form-control' readOnly={true} name="bar_barcode" value={formData.bar_barcode} onChange={handleChange} required />
        </div>
        <div className="col-md-6 mb-3">
          <label>ชื่อสินค้า</label>
          <input type="text" className='form-control' readOnly={true} name="bar_name" value={formData.bar_name} onChange={handleChange} required />
        </div>
        
        <div className="col-md-6 mb-3">
          <label>ราคา (จากราคา A)</label>
          <input type="text" className='form-control' readOnly={true} name="bar_price" value={formData.bar_price} onChange={handleChange} required />
        </div>

        <div className="col-md-6 mb-3">
          <label>จำนวนที่พิมพ์</label>
          <input type="text" className='form-control' name="bar_quantity" value={formData.bar_quantity} onKeyDown={handleKeyDown} onChange={handleChange} required />
          {errors.bar_quantity && <span className="text-danger">{errors.bar_quantity}</span>}
        </div>
        
      
        <div className="col-md-12 mt-4">
            <button type="submit" className='btn btn-secondary col-12'>เพิ่มรายการบาร์โค้ด</button>
        </div>

      </div>
    </form>
  );
};

export default InsertBarcodePrinting;

import React, {useState, useEffect, useCallback} from 'react'
import NavBar from './NavBar'
import axios from 'axios'
import moment from 'moment'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { LineChart, PieChart, BarChart, ChartContainer, BarPlot } from '@mui/x-charts';
import CountdownTimer from './CountDownTimer'

export default function Home() {

  const ShowSwal = () => {
    Swal.fire({
      icon: "warning",
      title: 'สำคัญกรุณาอ่าน !',
      text: 'กรุณาเพิ่มสินค้าในโปรแกรมเวอร์ชั่นนี้เท่านั้น ฐานข้อมูลถูกแยกกันแล้ว',
      showCancelButton: true,
      confirmButtonText: "เพิ่มข้อมูลสินค้าที่นี่",
      cancelButtonText: "เอาไว้ก่อน"
    }).then((result) => {
      if (result.isConfirmed) {
        window.location = '/PosProduct'
      } 
    })
  }

  const [EntryMode, setEntryMode] = useState(false);

  const [TodayReportData, setTodayReportData] = useState(
    {
      AllData: {
        TodayReport: {
          Bill: {
            AllBill: 0,
            Success: 0,
            Pause: 0,
          },
          Product: {
            All: 0,
            Success: 0,
            Return: 0
          },
          Income: {
            Today: 0
          }
        },
      },
    }
  );

  const [TodayReportOnlyPaymentCompletedData, setTodayReportOnlyPaymentCompletedData] = useState(
    {
      AllData: {
        TodayReport: {
          Bill: {
            AllBill: 0,
            Success: 0,
            Pause: 0,
          },
          Product: {
            All: 0,
            Success: 0,
            Return: 0
          },
          Income: {
            Today: 0
          }
        },
      },
    }
  );

  const [ChartData, setChartData] = useState({
      AllDate: [],
      AllIncome: []
  });

  const [YesterdayReportData, setYesterdayReportData] = useState(
    {
      AllData: {
        TodayReport: {
          Bill: {
            AllBill: 0,
            Success: 0,
            Pause: 0,
          },
          Product: {
            All: 0,
            Success: 0,
            Return: 0
          },
          Income: {
            Today: 0
          }
        },
      },
    }
  );

  const TodayDate = moment().locale('TH').format('YYYY-MM-DD').toString();
  const YesterdayDate = moment().locale('TH').subtract(1, 'day').format('YYYY-MM-DD').toString();

  const TodayReport = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/TodayReport`, {
          Date: TodayDate
        }, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      if(res.data.Status == true){
        console.log(res.data);
        setTodayReportData(res.data);
      }
    } catch (err) {
      console.error('Error searching bill history', err);
    }
  };

  const TodayReportOnlyPaymentCompleted = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/TodayReportOnlyPaymentCompleted`, {
          Date: TodayDate
        }, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      if(res.data.Status == true){
        console.log(res.data);
        setTodayReportOnlyPaymentCompletedData(res.data);
      }
    } catch (err) {
      console.error('Error searching bill history', err);
    }
  };

  const YesterdayReport = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/TodayReport`, {
          Date: YesterdayDate
        }, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      if(res.data.Status == true){
        console.log(res);
        setYesterdayReportData(res.data);
      }
    } catch (err) {
      console.error('Error searching bill history', err);
    }
  };

  const RequestDataPlotChart = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/7DaysIncome`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      if(res.data.Status == true){
        console.log(res.data);
        if(Object.entries(res.data.ChartData.PlotData.AllDate).length > 0){
          var KeyAllDate = Object.keys(res.data.ChartData.PlotData.AllDate);
          var KeyAllIncome = [];
          KeyAllDate.forEach(key => {
            KeyAllIncome.push(res.data.ChartData.PlotData.AllDate[key]);
          });
          setChartData((prevData) => ({
            ...prevData,
            AllDate: KeyAllDate,
            AllIncome: KeyAllIncome
          }));
        }
      }
    } catch (err) {
      console.error('Error searching bill history', err);
    }
  };

  useEffect(() => {
    TodayReport();
    YesterdayReport();
    TodayReportOnlyPaymentCompleted();
    RequestDataPlotChart();
    setTimeout(() => {
      setEntryMode(true);
    }, 30000);
    // ShowSwal();
  
  }, []);

  const request = useCallback(() => {
    TodayReport();
    YesterdayReport();
    TodayReportOnlyPaymentCompleted();
    RequestDataPlotChart();
    console.log(ChartData);
    

  }, [TodayReportData, YesterdayReportData, TodayReportOnlyPaymentCompletedData, ChartData]);
  

  return (
    <>
        <div className="background-gradient" style={{
          // backgroundImage: `url(${process.env.PUBLIC_URL}/assets/Background/bg.jpeg)`,
          // zIndex: 10,
          // backgroundRepeat: 'no-repeat',
          // backgroundPosition: 'top center',
          // backgroundAttachment: 'fixed'
        }}>
        <NavBar />
        {/* <div className="bg-res2" style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/Background/bg.jpeg)`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center',
              backgroundAttachment: 'fixed'
            }} > */}
          <div className='container-fluid' onClick={request}>
            {
              EntryMode ? (
                <>
                <div className="row pt-4">

                  <div className="col-md-12">
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <div className="frame-content text-bg-primary mb-3 p-3">
                          <div className="d-flex">
                            <h1 className="ps-3 h1-oversize text-light">
                              <i className="bi bi-file-earmark-fill" />
                            </h1>
                            <h3 className="ps-3 text-light sarabun">
                                ใบเสร็จทั้งหมด
                                <h3 className="text-light sarabun">
                                  {TodayReportData.AllData.TodayReport.Bill.AllBill}
                                </h3>
                            </h3>
                            
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="frame-content text-bg-success mb-3 p-3">
                          <div className="d-flex">
                            <h1 className="ps-3 h1-oversize text-light">
                              <i className="bi bi-file-earmark-check-fill" />
                            </h1>
                            <h3 className="ps-3 text-light sarabun">
                                ใบเสร็จที่ขายสำเร็จ
                                <h3 className="text-light sarabun">
                                  {TodayReportData.AllData.TodayReport.Bill.Success}
                                </h3>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="frame-content text-bg-danger mb-3 p-3">
                          <div className="d-flex">
                            <h1 className="ps-3 h1-oversize text-light">
                              <i className="bi bi-file-earmark-x-fill" />
                            </h1>
                            <h3 className="ps-3 text-light sarabun">
                                ใบเสร็จที่ยกเลิก
                                <h3 className="text-light sarabun">
                                  {TodayReportData.AllData.TodayReport.Bill.Pause}
                                </h3>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="frame-content text-bg-warning mb-3 p-3">
                          <div className="d-flex">
                            <h1 className="ps-3 h1-oversize text-light">
                              <i className="bi bi-file-earmark-fill" />
                            </h1>
                            <h3 className="ps-3 text-light sarabun">
                                ใบเสร็จทั้งหมดเมื่อวาน 
                                <h3 className="text-light sarabun">
                                  {YesterdayReportData.AllData.TodayReport.Bill.AllBill}
                                </h3>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <div className="frame-content text-bg-primary mb-3 p-3">
                          <div className="d-flex">
                            <h1 className="ps-3 h1-oversize text-light">
                              <i className="bi bi-bag-fill" />
                            </h1>
                            <h3 className="ps-3 text-light sarabun">
                                สินค้าทั้งหมด
                                <h3 className="text-light sarabun">
                                  {TodayReportData.AllData.TodayReport.Product.All}
                                </h3>
                            </h3>
                            
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="frame-content text-bg-success mb-3 p-3">
                          <div className="d-flex">
                            <h1 className="ps-3 h1-oversize text-light">
                              <i className="bi bi-bag-check-fill" />
                            </h1>
                            <h3 className="ps-3 text-light sarabun">
                                สินค้าที่ขายสำเร็จ
                                <h3 className="text-light sarabun">
                                  {TodayReportData.AllData.TodayReport.Product.Success}
                                </h3>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="frame-content text-bg-danger mb-3 p-3">
                          <div className="d-flex">
                            <h1 className="ps-3 h1-oversize text-light">
                              <i className="bi bi-bag-x-fill" />
                            </h1>
                            <h3 className="ps-3 text-light sarabun">
                                สินค้าที่ยกเลิก
                                <h3 className="text-light sarabun">
                                  {TodayReportData.AllData.TodayReport.Product.Return}
                                </h3>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="frame-content text-bg-warning mb-3 p-3">
                          <div className="d-flex">
                            <h1 className="ps-3 h1-oversize text-light">
                              <i className="bi bi-bag-fill" />
                            </h1>
                            <h3 className="ps-3 text-light sarabun">
                                สินค้าทั้งหมดเมื่อวาน 
                                <h3 className="text-light sarabun">
                                {YesterdayReportData.AllData.TodayReport.Product.All}
                                </h3>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="frame-content mb-3 mt-2 pt-4 pb-3 ps-5">
                      <div className="row">
                        <div className="col-md-3">
                          <h4 className="mb-3 sarabun">
                            ยอดขายวันนี้ (ยอดรวม)
                          </h4>
                          <h1 className="poppins-font text-primary text-start">
                            ฿{parseFloat(TodayReportData.AllData.TodayReport.Income.Today).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                          </h1>
                        </div>
                        <div className="col-md-3">
                          <h4 className="mb-3 sarabun">
                            ยอดขายวันนี้ (ที่ชำระเงินแล้ว)
                          </h4>
                          <h1 className="poppins-font text-success text-start">
                            ฿{parseFloat(TodayReportOnlyPaymentCompletedData.AllData.TodayReport.Income.Today).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                          </h1>
                        </div>
                        <div className="col-md-3">
                          <h4 className="mb-3 sarabun">
                            ยอดขายวันนี้ (รอการชำระเงิน)
                          </h4>
                          <h1 className="poppins-font text-danger text-start">
                            ฿{parseFloat(TodayReportData.AllData.TodayReport.Income.Today - TodayReportOnlyPaymentCompletedData.AllData.TodayReport.Income.Today).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                          </h1>
                        </div>
                        <div className="col-md-3">
                          <h4 className="mb-3 sarabun">
                            ยอดขายเมื่อวาน (ยอดรวม)
                          </h4>
                          <h1 className="poppins-font text-warning text-start">
                            ฿{parseFloat(YesterdayReportData.AllData.TodayReport.Income.Today).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                          </h1>
                        </div>
                      </div>
                    </div>
                    
                  </div>

                </div>


                {
                  Object.entries(ChartData.AllDate).length > 0 ? (
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <div className="frame-content">
                          <div className="d-flex justify-content-center">
                            {/* <LineChart 
                              xAxis={[
                                { data: ChartData.AllDate, scaleType: 'point' }
                              ]}
                              series={[
                                {
                                  data: ChartData.AllIncome,
                                  area: true
                                },
                              ]}
                              width={900}
                              height={300}
                            /> */}
                            <BarChart
                              width={700}
                              height={400}
                              series={[
                                { data: ChartData.AllIncome},
                                // { data: ChartData.AllDate, label: 'uv', id: 'uvId' },
                              ]}
                              xAxis={[{ data: ChartData.AllDate, scaleType: 'band' }]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                          <div className="frame-content">
                            <div className="d-flex justify-content-center">
                              <PieChart
                                series={[
                                  {
                                    data: [
                                      { id: 0, value: TodayReportData.AllData.TodayReport.Income.Today, label: 'Total' },
                                      { id: 1, value: TodayReportOnlyPaymentCompletedData.AllData.TodayReport.Income.Today, label: 'Purchased' },
                                    ],
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    startAngle: -90,
                                    // endAngle: 180,
                                    cx: 150,
                                    cy: 150,
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                  },
                                ]}
                                width={700}
                                height={400}
                              />
                            </div>
                          </div>
                        </div>
                    </div>
                  ) : null
                }
                </>

              ) : (
                  <div className="row pt-5 mx-auto vh-100">
                    <h1 className='text-center cinzel-decorative-regular h1-oversize'>
                        ARE YOU READY FOR 2025 ?<br/>
                        IT WILL ARRIVE SOON !<br/>
                        Let make it done before new year !
                    </h1>
                    <div className='pt-3' align="center">
                      <h6 className='text-center text-dark col-4'>
                        ก่อนที่ปีนี้จะสิ้นสุดลง
                        ขอให้ทุกท่านเก็บเกี่ยวช่วงเวลาที่ดี ๆ และความทรงจำอันงดงามไว้ในใจ
                        ขอให้ปีใหม่นำพาความสุข ความสำเร็จ และสุขภาพที่ดีมาสู่ทุกคน
                        สิ่งใดที่ผ่านไปแล้วขอให้เป็นบทเรียน
                        สิ่งใดที่รออยู่ขอให้เป็นโอกาสที่ดี
                        ขอบคุณปีเก่าที่ให้โอกาสเราได้เรียนรู้
                        และต้อนรับปีใหม่ด้วยพลังและความหวังที่สดใส <br/>
                        ขอให้ชีวิตทุกคนเต็มไปด้วยรอยยิ้มและความสุขในทุก ๆ วัน
                      </h6>
                    </div>
                    <div className="pt-3 text-center">
                      <h1 className='display-6 poppins-font'>
                        Countdown Together
                      </h1>
                      <CountdownTimer toDate={'2024-12-31T23:59:59'} />
                    </div>
                    <div className="col-12 text-center pt-2 pb-5">
                      <button className="btn btn-dark shadow-animation" onClick={() => setEntryMode(true)}>
                        <i className="bi bi-box-arrow-in-right"></i> แดชบอร์ด
                      </button>
                    </div>
                  </div>
                )
              
            }
          </div>
        </div>
    </>
  )
}

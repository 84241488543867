// src/PosProductForm.js
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import ProductValidate from '../Function/ProductValidation';
import uniqid from 'uniqid';
import moment from 'moment';
import BarcodeGen from '../Function/BarcodeGenerater';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const InsertProductForm = ({UpdateStatus}) => {
  const MySwal = withReactContent(Swal);
  const SwalShow = (status, message) => {
    if(status == true){
        MySwal.fire({
            title: 'สำเร็จ',
            text: message,
            icon: 'success'
        })
    } else{
        MySwal.fire({
            title: 'ไม่สำเร็จ',
            text: message,
            icon: 'error'
        })
    }
  };
  const [formData, setFormData] = useState({
    pro_name: '',
    pro_barcode: '',
    pro_category: '',
    pro_priceA: '0.00',
    pro_priceB: '0.00',
    pro_priceC: '0.00',
    pro_cost: '0.00',
    pro_unit: '',
    pro_date: moment().locale('TH').format('YYYY-MM-DD'),
    pro_barstatus: 'customize',
    pro_nowstock: '0.00',
    pro_genid: ''
  });

  const [Categories, setCategories] = useState([]);
  const [Units, setUnits] = useState([]);
  // const [BarcodeGenerater, setBarcodeGenerater] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    const error = ProductValidate(name, value);
    setErrors({
      ...errors,
      [name]: error
    });
  };

  const RequestCategories = async() => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/Product/Categories`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      setCategories(res.data);
    } catch (err) {
      console.error('Error fetching bill history', err);
    }
  }

  const RequestUnits = async() => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/Product/Units`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      setUnits(res.data);
    } catch (err) {
      console.error('Error fetching bill history', err);
    }
  }

  const RequestGenerateBarcode = async() => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/Product/GenerateBarcode`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      setFormData(prev => ({...prev, ['pro_barcode']: BarcodeGen(res.data)}));
      setFormData(prev => ({...prev, ['pro_genid']: uniqid('pdt-', 10)}));
      console.log(res.data);
    } catch (err) {
      console.error('Error fetching bill history', err);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = ProductValidate(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/InsertProductToDB`, formData);
      console.log('Product added:', response.data);
      // Reset form after submission
      if(response.data.Status == true){
        SwalShow(response.data.Status, response.data.Message);
        // setFormData(prev => ({
        //   pro_name: '',
        //   pro_barcode: '',
        //   pro_category: '',
        //   pro_priceA: '0.00',
        //   pro_priceB: '0.00',
        //   pro_priceC: '0.00',
        //   pro_cost: '0.00',
        //   pro_unit: '',
        //   pro_date: moment().locale('TH').format('YYYY-MM-DD'),
        //   pro_barstatus: 'product',
        //   pro_nowstock: '0.00',
        //   pro_genid: uniqid('pdt-', 10)
        // }));
        setFormData({...formData, ['pro_genid']: uniqid('pdt-', 10)});
        UpdateStatus(true);
        setErrors({});
        increment();
      } else{
        SwalShow(response.data.Status, response.data.Message);
      }
      
    } catch (error) {
      console.error('There was an error adding the product!', error);
    }
  };

  useEffect(() => {
    RequestCategories();
    RequestUnits();
    RequestGenerateBarcode();
  
  }, []);

  const increment = useCallback(() => {
    RequestCategories();
    RequestUnits();
    RequestGenerateBarcode();
    
  }, [Categories, Units, FormData]);
  

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label>ชื่อสินค้า</label>
          <input type="text" className='form-control' name="pro_name" value={formData.pro_name} onChange={handleChange} required />
          {errors.pro_name && <span className="text-danger">{errors.pro_name}</span>}
        </div>
      
        <div className="col-md-3 mb-3">
          <label>บาร์โค้ด</label>
          <input type="text" className='form-control' name="pro_barcode" value={formData.pro_barcode} onChange={handleChange} required />
          {errors.pro_barcode && <span className="text-danger">{errors.pro_barcode}</span>}
        </div>
        
        <div className="col-md-3 mb-3">
          <label>ประเภทบาร์โค้ด</label>
          <select name="pro_barstatus" className="form-select" defaultValue={formData.pro_barstatus} value={formData.pro_barstatus} onChange={handleChange}>
            <option value="product" >ติดมากับสินค้า</option>
            <option value="customize">กำหนดเอง</option>
          </select>
          {/* <input type="number" className='form-control mb-2' name="pro_barstatus" value={formData.pro_barstatus} onChange={handleChange} required /> */}
          {errors.pro_barstatus && <span className="text-danger">{errors.pro_barstatus}</span>}
        </div>

        <div className="col-md-3 mb-3">
          <label>หมวดหมู่</label>
          <select name="pro_category" className="form-select" defaultValue={formData.pro_category} value={formData.pro_category} onChange={handleChange}>
            <option value="" >กรุณาเลือก</option>
            {
              Categories.map((category) => 
                <option key={category.ctg_genid} value={category.ctg_name}>{category.ctg_name}</option>
              )
            }
          </select>
          {errors.pro_category && <span className="text-danger">{errors.pro_category}</span>}
        </div>

        <div className="col-md-3 mb-3">
          <label>หน่วยนับ</label>
          <select name="pro_unit" className="form-select" defaultValue={formData.pro_unit} value={formData.pro_unit} onChange={handleChange}>
            <option value="" >กรุณาเลือก</option>
            {
              Units.map((unit) => 
                <option key={unit.unt_genid} value={unit.unt_name}>{unit.unt_name}</option>
              )
            }
          </select>
          {errors.pro_unit && <span className="text-danger">{errors.pro_unit}</span>}
        </div>

        <div className="col-md-2 mb-3">
          <label>ราคา A</label>
          <input type="text" className='form-control' name="pro_priceA" value={formData.pro_priceA} onChange={handleChange} required />
          {errors.pro_priceA && <span className="text-danger">{errors.pro_priceA}</span>}
        </div>
      
        <div className="col-md-2 mb-3">
          <label>ราคา B</label>
          <input type="text" className='form-control' name="pro_priceB" value={formData.pro_priceB} onChange={handleChange} required />
          {errors.pro_priceB && <span className="text-danger">{errors.pro_priceB}</span>}
        </div>
      
        <div className="col-md-2 mb-3">
          <label>ราคา C</label>
          <input type="text" className='form-control' name="pro_priceC" value={formData.pro_priceC} onChange={handleChange} required />
          {errors.pro_priceC && <span className="text-danger">{errors.pro_priceC}</span>}
        </div>

        <div className="col-md-3 mb-3">
          <label>ราคาต้นทุน</label>
          <input type="text" className='form-control' name="pro_cost" value={formData.pro_cost} onChange={handleChange} required />
          {errors.pro_cost && <span className="text-danger">{errors.pro_cost}</span>}
        </div>


        <div className="col-md-3 mb-3">
          <label>สินค้ามีอยู่จำนวน</label>
          <input type="text" className='form-control' name="pro_nowstock" value={formData.pro_nowstock} onChange={handleChange} required />
          {errors.pro_nowstock && <span className="text-danger">{errors.pro_nowstock}</span>}
        </div>
        
        <div className="col-md-3 mb-3">
          <label>วันที่ทำรายการ</label>
          <input disabled={true} type="date" className='form-control' name="pro_date" value={formData.pro_date} onChange={handleChange} required />
          {errors.pro_date && <span className="text-danger">{errors.pro_date}</span>}
        </div>
        
        <div className="col-md-3 mb-3">
          <label>รหัสสินค้าพิเศษ</label>
          <input disabled={true} type="text" className='form-control' name="pro_genid" value={formData.pro_genid} onChange={handleChange} required />
          {errors.pro_genid && <span className="text-danger">{errors.pro_genid}</span>}
        </div>
      
      <div className="col-md-12 mt-4">
        <button type="submit" className='btn btn-secondary col-12'>เพิ่มรายการสินค้า</button>
      </div>

      </div>
    </form>
  );
};

export default InsertProductForm;

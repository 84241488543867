import React, { useState } from 'react';


    const Convert = (amountNumber) => {
        const numberFormat = parseFloat(amountNumber).toFixed(2);
        const [number, fraction] = numberFormat.split('.');
        
        let ret = '';
        const baht = ReadNumber(number);
        if (baht !== '') ret += baht + 'บาท';
        
        const satang = ReadNumber(fraction);
        if (satang !== '') ret += satang + 'สตางค์';
        else ret += 'ถ้วน';

        return ret;
    };

    const ReadNumber = (number) => {
        const positionCall = ["แสน", "หมื่น", "พัน", "ร้อย", "สิบ", ""];
        const numberCall = ["", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า"];
        number = Number(number);
        let ret = '';

        if (number === 0) return ret;
        if (number > 1000000) {
            ret += ReadNumber(Math.floor(number / 1000000)) + "ล้าน";
            number = number % 1000000;
        }

        let divider = 100000;
        let pos = 0;
        while (number > 0) {
            const d = Math.floor(number / divider);
            ret += ((divider === 10 && d === 2) ? "ยี่" : 
                ((divider === 10 && d === 1) ? "" : 
                ((divider === 1 && d === 1 && ret !== "") ? "เอ็ด" : numberCall[d])));
            ret += (d ? positionCall[pos] : "");
            number = number % divider;
            divider = divider / 10;
            pos++;
        }
        return ret;
    };


export default Convert;

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import NavBar from './NavBar';
import PosSearchProductList from '../Function/PosSearchProductList';
import InsertBarcodePrinting from './InsertBarcodePrinting';
import ShowBarcodeQueues from './ShowBarcodeQueues';


export default function BarcodePrinting() {

    const [ProductData, setProductData] = useState({});
    const [ProductDataMode, setProductDataMode] = useState('');
    const [UpdateStatus, setUpdateStatus] = useState(false);

    const handleSetProductData = (Data) => {
        setProductData(Data);
        setProductDataMode('Insert');
    };

    const handleSetUpdateStatus = (status) => {
        setUpdateStatus(status);
    };

    useEffect(() => {
      if(UpdateStatus){
        setUpdateStatus(false);
        setProductDataMode('');
      }
    
    }, [UpdateStatus]);
    


    return (
        <>
            <NavBar/>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-4">
                        <PosSearchProductList ProductData={handleSetProductData} UpdateStatus={UpdateStatus}/>
                    </div>
                    <div className="col-md-8">
                        <div className="row mb-1">
                            <div className="col-md-12">
                                {
                                    ProductDataMode != 'Insert' ? (
                                        <button className="btn btn-primary col-12">
                                            กรุณาเลือกรายการ
                                        </button>
                                    ) : (
                                        <button className="btn btn-danger col-12">
                                            กำลังแก้ไขรายการ
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                    {
                                        ProductDataMode == 'Insert' ? (
                                            <div className="card" style={{marginTop: '0rem'}}>
                                                <div className="card-body">
                                                    <InsertBarcodePrinting ProductData={ProductData} UpdateStatus={handleSetUpdateStatus} />
                                                </div>
                                            </div>
                                        ) : null
                                    }
                            </div>
                            <div className="col-md-6 mt-3">
                                <ShowBarcodeQueues UpdateStatus={handleSetUpdateStatus} />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}
import { useState, useRef, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import BillNumber from '../../Function/BillNumber';
import ShortText from '../../Function/Text';

const VerifyModal = ({BillData}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const [PurchaseHistory, setPurchaseHistory] = useState([]);
    // const [BillData, setBillData] = useState({
    //     "Status": true,
    //     "bill_id": "",
    //     "bill_reference": "",
    //     "bill_address": "",
    //     "bill_contact": "",
    //     "bill_name": "",
    //     "bill_payment": "",
    //     "bill_time": "",
    //     "bill_date": "",
    //     "bill_buyer_taxid": "",
    //     "st_id": null,
    //     "st_bill_ref": "",
    //     "st_status": "",
    //     "st_time": "",
    //     "st_date": "",
    //     "customer_id": null,
    //     "customer_name": "",
    //     "customer_bill_number": "",
    //     "customer_address": "",
    //     "customer_bill_file": "",
    //     "customer_balance": "",
    //     "customer_amount": "",
    //     "customer_phone": "",
    //     "customer_bill_reference": "",
    //     "customer_bill_date": "",
    //     "customer_bill_timestamp": "",
    //     "customer_note": null,
    //     "customer_payment_method": "",
    //     "bill_dis_amount": 0,
    //     "bill_dis_code": "",
    //     "bill_dis_id": 0,
    //     "bill_dis_ref": ""
    // });

    // const showHistory = async () => {
    //     try {
    //       const res = await axios.get("http://localhost:3001/BillCheckExist/"+BillReference, {
    //         headers: {
    //           "Access-Control-Allow-Origin": "*",
    //           "Content-Type": "application/json",
    //         },
    //       });
    //           if(res.data.Status == true){
    //               setBillData(res.data);
    //           } else{
    //               navigate('/sPosHome');
    //           }
    //     //   console.log(res);
    //     } catch (err) {
    //       console.error('Error fetching bill history', err);
    //     //   navigate('/sPosHome');
    //     }
    //   };


    const forwardDays = (fromday, day) => {
        const inputTimestamp = new Date(fromday).getTime();
        const newTimestamp = inputTimestamp + (day * 24 * 60 * 60 * 1000);
        const newDate = new Date(newTimestamp).toISOString().split('T')[0];
        return newDate;
      };

    const ChangeStatusBill = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/ChangeStatusBill/${BillData.bill_reference}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
        //   console.log(res);
        } catch (err) {
          console.error('Error searching bill history', err);
        }
      };

      async function CheckPurchase(){
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/CheckPurchase/${BillData.bill_reference}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
            // console.log(res);
            if(res.data.Status == true){
                setPurchaseHistory(res.data.CheckPurchaseBillResult);
                // navigate("/");
            }
            // console.log(PurchaseHistory);
        } catch (err) {
        //   console.error('Error fetching bill history', err);
        }
      }

      useEffect(() => {
        CheckPurchase();
        
      }, [CheckPurchase, PurchaseHistory])
    //   


    return (
        <>
            {
                BillData.st_status != "pause" ? (
                    <button className="btn btn-secondary col-12" onClick={ChangeStatusBill}>
                        <i className="bi bi-trash" /> ยกเลิกการขายนี้
                    </button>
                ) : BillData.st_status == "pause" ? (
                    <>
                        {
                            (PurchaseHistory.length < 2) && (moment().locale('TH').format("YYYY-MM-DD") <= forwardDays(moment(BillData.bill_date).format("YYYY-MM-DD"), 7)) ? (
                                <button className="btn btn-outline-secondary col-12" onClick={ChangeStatusBill}>
                                    <i className="bi bi-recycle" /> กู้คืนรายการขายนี้
                                </button>
                            ) : (
                                <button type="button" className="btn btn-danger col-12">
                                    <i className="bi bi-file-earmark-excel" /> หมดเวลากู้คืนรายการขาย
                                </button>
                            )
                        }
                    </>
                ) : null
            }
            <Modal centered show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">ยืนยันตัวตน</h5>
                    </Modal.Title>
                </Modal.Header>
                <form>
                <Modal.Body>
                    <div>

                    </div>
                    

                </Modal.Body>
                {/* <Modal.Footer> */}
                    <div className="modal-footer d-inline" style={{justifyContent: 'center', display: 'block'}}>
                        <div className="row">
                            <div className="col-6">
                                <button onClick={handleClose} type="button" className="btn btn-outline-secondary col-12" data-bs-dismiss="modal">ยกเลิก</button>
                            </div>
                            <div className="col-6">
                                <button onClick={handleClose} type="submit" data-bs-dismiss="modal" name="create" className="btn btn-secondary col-12">สร้างใบเสร็จ</button>
                            </div>
                        </div>
                    </div>
                {/* </Modal.Footer> */}
                </form>
            </Modal>

        </>
    );
};

export default VerifyModal;
import { useState, useRef, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import BillNumber from '../../Function/BillNumber';
import ShortText from '../../Function/Text';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function ImportProductFromAnotherBill({ToBillReference, UpdateStatus}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [EnableStatus, setEnableStatus] = useState(true);
    const MySwal = withReactContent(Swal);
    const [FromBillReference, setFromBillReference] = useState('');
    const SwalShow = (status, message) => {
        if(status == true){
            MySwal.fire({
                title: 'สำเร็จ',
                text: message,
                icon: 'success'
            })
        } else{
            MySwal.fire({
                title: 'ไม่สำเร็จ',
                text: message,
                icon: 'error'
            })
        }
    };

    const InsertProductList = async (e) => {
        e.preventDefault();
        if(FromBillReference == ''){
            SwalShow(false, 'กรุณาสแกนเลขที่ทำรายการ');
            return;
        } else{
            setEnableStatus(true);
            try{
                const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/ImportProductList/${FromBillReference}/${ToBillReference}`, {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Content-Type": "application/json",
                    },
                  });
                    if(res.data.Status == true){
                        setEnableStatus(false);
                        UpdateStatus(true);
                        SwalShow(res.data.Status, res.data.Message);
                        setFromBillReference('');
                    }
                    if(res.data.Status == false){
                        setEnableStatus(false);
                        SwalShow(res.data.Status, res.data.Message);
                        setFromBillReference('');
                    }
              } catch(error){
                console.log(error);
            }
        }
    }

    const handleChangeFromBillReference = (e) => {
        setFromBillReference(e.target.value);
        if(e.target.value.length > 0){
            setEnableStatus(false);
        }
    }
    



    return (
        <>
            <button className="btn btn-success col-12" type="button" onClick={handleShow}>
                <i className="bi bi-file-earmark-arrow-down" /> นำเข้ารายการสินค้า
            </button>
            <Modal centered size="lg" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>นำเข้ารายการสินค้าจากใบเสร็จอื่น</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <img src={`${process.env.PUBLIC_URL}/assets/Images/barcode.png`} alt="" className="img-fluid" />
                            <h4 className="text-center text-danger">สแกนเพื่อนำเข้า</h4>
                            <form>
                                <div className="row">
                                    <label htmlFor="">เลขที่ใบเสร็จ</label>
                                    <div className="col-9">
                                        <input type="text" value={FromBillReference} onChange={handleChangeFromBillReference} className="form-control" />
                                    </div>
                                    <div className="col-3">
                                        <button type="submit" onClick={InsertProductList} disabled={EnableStatus} className="btn btn-secondary col-12">นำเข้ารายการ</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>

                <div className="modal-footer" style={{justifyContent: 'center', display: 'block'}}>
                    <div className="row">
                        <button type="button" onClick={handleClose} className="btn btn-outline-secondary col-12">ยกเลิก</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ImportProductFromAnotherBill;
import {React, useCallback, useEffect, useState} from 'react'
import axios from 'axios'
import ShortText from './Text';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

export default function SearchContactList({ContactData, UpdateStatus}) {
    const [ContactList, setContactList] = useState([]);
    const [SearchKeywords, setSearchKeywords] = useState('');
    const MySwal = withReactContent(Swal);


    const RequestDataContactByGenid = async(ContactGenID) => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/GetContactByGenID/${ContactGenID}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
            ContactData(res.data.Result);
            console.log(res.data.Result);
        } catch (err) {
          console.error('Error fetching bill history', err);
        }
      }

    const handleSearchContact = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/SearchContactList`, {
                SearchKeywords: SearchKeywords
            },
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            });
            if(res.status == 200){
                setContactList(res.data.Result);
                console.log(res.data.Result);
            }

        } catch (err) {
            console.error('Error fetching bill history', err);
        }
    }
    


    const handleChangeKeywords = (e) => {
        setSearchKeywords(e.target.value);
        // handleSearchProduct();
    }


    useEffect(() => {
        if (SearchKeywords || UpdateStatus) {
            handleSearchContact();
        } else {
            handleSearchContact();
        }
    }, [SearchKeywords, UpdateStatus]);


    const ListIncrement = useCallback(() => {
    if (SearchKeywords || UpdateStatus) {
        handleSearchContact();
    } else {
        handleSearchContact();
    }
    
    }, [SearchKeywords, UpdateStatus]);
    

    const ListClickEvent = (ContactGenID) => {
        RequestDataContactByGenid(ContactGenID);
        ListIncrement();
    }
      
    
    const ContactComponent = ({ ContactList }) => {
        const contactListComponents = [];
    
        if (Object.keys(ContactList).length > 0) {
            for (let id = 0; id < ContactList.length; id++) {
                const Contact = ContactList[id];
                contactListComponents.push(
                    <li key={Contact.contact_id} onClick={() => ListClickEvent(Contact.contact_genid)} className="list-group-item cursor-pointer">
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="row">
                                <div className="">
                                    {ShortText(Contact.contact_name, 30)}
                                </div>
                                {/* <div className="lead text-secondary">
                                    {"#"+Product.bar_id+" "}
                                </div> */}
                            </div>
                                {
                                    Contact.contact_type == 'โรงเรียน' ? (
                                        <span className="badge text-bg-primary rounded-pill">
                                            {Contact.contact_type}
                                        </span>
                                    ) : Contact.contact_type == 'หน่วยงานราชการ' ? (
                                        <span className="badge text-bg-danger rounded-pill">
                                            {Contact.contact_type}
                                        </span>
                                    ) : Contact.contact_type == 'หน่วยงานอิสระ' ? (
                                        <span className="badge text-bg-success rounded-pill">
                                            {Contact.contact_type}
                                        </span>
                                    ) : Contact.contact_type == 'ร้านค้าพันธมิตร' ? (
                                        <span className="badge text-bg-warning rounded-pill">
                                            {Contact.contact_type}
                                        </span>
                                    ) : (
                                        <span className="badge text-bg-secondary rounded-pill">
                                             {Contact.contact_type}
                                        </span>
                                    )
                                }   
                        </div>
                        {/* <div className="row mt-2">
                            <div className="col-md-4 text-start">
                                <span className="badge text-bg-success rounded-pill">
                                    {Product.pro_priceA == 0 ? '0.00' : Product.pro_priceA}
                                </span>
                            </div>
                            <div className="col-md-4 text-center">
                                <span className="badge text-bg-warning rounded-pill">
                                    {Product.pro_priceB == 0 ? '0.00' : Product.pro_priceB}
                                </span>
                            </div>
                            <div className="col-md-4 text-end">
                                <span className="badge text-bg-danger rounded-pill">
                                    {Product.pro_priceC == 0 ? '0.00' : Product.pro_priceC}
                                </span>
                            </div>
                        </div> */}
                    </li>
                );
            }
        }
    
        return (
            <>
                {
                    contactListComponents.length > 0 ? (
                        <ol className="list-group mt-3">{contactListComponents}</ol>
                    ) : null
                }
            </>
        );
    };

    // const handleAddItem = (Barcode) => {
    //     // AddItemInBag(Barcode);
    // }

    const SwalShow = (status, message) => {
        if(status == true){
            MySwal.fire({
                title: 'สำเร็จ',
                text: message,
                icon: 'success'
            })
        } else{
            MySwal.fire({
                title: 'ไม่สำเร็จ',
                text: message,
                icon: 'error'
            })
        }
    };
    
    
  return (
    <>
        <input type="text" onChange={handleChangeKeywords} value={SearchKeywords} className='col-12 form-control' placeholder='ค้นหารายการผู้ติดต่อ' />
        <div className="row">
            <div className="col-12 scrollspy-example scrollspy-pos">
                <ContactComponent ContactList={ContactList} />
            </div>
        </div> 
    </>
    
  )
}

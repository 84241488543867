import {React, useState, useEffect} from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

export default function AddItemBag({BillData, BillReference, AllItemBag}) {
    const [BarcodeDefault, setBarcodeDefault] = useState('');
    const MySwal = withReactContent(Swal);

    const LimitKeyInput = (event) => {
        const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', '.', 'Enter'];
        const isNumberKey = event.key >= '0' && event.key <= '9';
    
        if (!allowedKeys.includes(event.key) && !isNumberKey) {
            event.preventDefault();
        }
    };

    const handleChange = (e) => {
        setBarcodeDefault(e.target.value);
      };

    const AddItemInBag = async (event) => {
        event.preventDefault();
        const Barcode = BarcodeDefault;
        console.log(BarcodeDefault);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/AddItemInBag/${BillReference}/${Barcode}`, 
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            });
            if(res.data.status == true){
                setBarcodeDefault('');
            }
            if(res.data.status == false){
                SwalShow(res.data.status, res.data.message);
                setBarcodeDefault('');
            }
            console.log(res);
        } catch (err) {
            console.error('Error fetching bill history', err);
            setBarcodeDefault('');
        }
      }

    //   useEffect(() => {
    //     setBarcodeDefault('');
    //   }, [BarcodeDefault])

    const SwalShow = (status, message) => {
        if(status == true){
            MySwal.fire({
                title: 'สำเร็จ',
                text: message,
                icon: 'success'
            })
        } else{
            MySwal.fire({
                title: 'ไม่สำเร็จ',
                text: message,
                icon: 'error'
            })
        }
    };
    

  return (
    <>
    {
        BillData.st_status == "process" ? (
            
            AllItemBag.length >= 15 ? (
                <>
                    <div className="col-12">
                        <div className="text-center card card-body toast-blink mt-2 mb-2">
                            <div className="h5 mb-2 mt-2 text-danger">
                                <i className="bi bi-bag-x" /> สินค้าเพิ่มได้ไม่เกิน 15 รายการเท่านั้น
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <form onSubmit={AddItemInBag}>
                        <div align="center" style={{marginTop: '1rem'}}>
                            <div className="row">
                                <div className="col-9">
                                    <input type="text" autoFocus onChange={handleChange} value={BarcodeDefault} autoComplete="off" onKeyDown={LimitKeyInput} className="form-control col-12" name="AddItemBarcode" placeholder="บาร์โค้ด" />
                                    {/* <ul className="list-group search col-12" align="left" id="search_result" /> */}
                                </div>
                                <div className="col-3">
                                    <button type="submit" className="btn btn-outline-secondary col-12" >
                                        <i className="bi bi-plus-lg" /> เพิ่ม
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </>
            )
        

        ) : BillData.st_status == "success" ? (
            <div className="col-12">
                <div className="text-center card card-body frame-blink mt-2 mb-2">
                    <div className="h5 mb-2 mt-2 text-success">
                        <i className="bi bi-bag-check" /> การขายสำเร็จแล้ว
                    </div>
                </div>
            </div>
        ) : null
    }
    </>
  )
}

// ContactForm.js

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { validateContactForm } from '../Function/ContactValidation';
import uniqid from 'uniqid';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const InsertContact = ({ UpdateStatus }) => {
    const MySwal = withReactContent(Swal);
    const [contact, setContact] = useState({
        contact_name: '',
        contact_phone: '',
        contact_tel: '',
        contact_email: '',
        contact_taxid: '',
        contact_type: 'ลูกค้าทั่วไป',
        contact_address: '',
        contact_genid: uniqid('ct-')
    });

    const [errors, setErrors] = useState({});

    //   useEffect(() => {
    //     if (contactId) {
    //       axios.post(`${process.env.REACT_APP_API_SERVER}/api/contacts/${contactId}`)
    //         .then(response => {
    //           setContact(response.data);
    //         })
    //         .catch(error => {
    //           console.error('There was an error fetching the contact data!', error);
    //         });
    //     }
    //   }, [contactId]);

    const SwalShow = (status, message) => {
        if(status == true){
        MySwal.fire({
            title: 'สำเร็จ',
            text: message,
            icon: 'success'
        })

        } else{
        MySwal.fire({
            title: 'ไม่สำเร็จ',
            text: message,
            icon: 'error'
        })
        }
    };

    const handleChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value });
    };

    const LimitKeyInput = (event) => {
        const isNumberKey = event.key >= '0' && event.key <= '9';
    
        if (isNumberKey) {
            event.preventDefault();
        }
    };

    const handleKeyNumberDigitsCheck = (e) => {
        const key = e.key;
    
        // Allow numeric keys, backspace, and delete
        if (!/[0-9]/.test(key) && key !== 'Backspace' && key !== 'Delete') {
          e.preventDefault();
        }
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        const validationErrors = validateContactForm(contact);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0){
            return;
        } else{
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/InsertContactData`, contact);
                console.log('Contact added:', response.data);
                if(response.data.Status == true){
                    SwalShow(response.data.Status, response.data.message);
                    setContact({
                        contact_name: '',
                        contact_phone: '',
                        contact_tel: '',
                        contact_email: '',
                        contact_taxid: '',
                        contact_type: 'ลูกค้าทั่วไป',
                        contact_address: '',
                        contact_genid: uniqid('ct-')
                    });
                    UpdateStatus(true);
                    setErrors({});
                    increment();
        
                } else{
                    SwalShow(response.data.Status, response.data.message);
                }
                
            } catch (error) {
                console.error('There was an error adding the contact!', error);
            }
        }
    };

    const increment = useCallback(() => {
        
    }, [contact]);

  return (
    <form onSubmit={handleSubmit}>
        <div className="row">
            <div className='col-md-6'>
                <label>GenID</label>
                <input type="text" name="contact_taxid" value={contact.contact_genid} onChange={handleChange} disabled={true} className='form-control'/>
            </div>
            <div className='col-md-6'>
                <label>ประเภทลูกค้า</label>
                <select name="contact_type" value={contact.contact_type} onChange={handleChange} required className='form-select'>
                    <option value="ลูกค้าทั่วไป">ลูกค้าทั่วไป</option>
                    <option value="ร้านค้าพันธมิตร">ร้านค้าพันธมิตร</option>
                    <option value="โรงเรียน">โรงเรียน</option>
                    <option value="หน่วยงานราชการ">หน่วยงานราชการ</option>
                    <option value="หน่วยงานอิสระ">หน่วยงานอิสระ</option>
                </select>
                {errors.contact_type && <span className='text-danger'>{errors.contact_type}</span>}
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-md-6">
                <label>ชื่อติดต่อ</label>
                <input type="text" name="contact_name" value={contact.contact_name} onChange={handleChange} onKeyDown={LimitKeyInput} required className='form-control'/>
                {errors.contact_name && <span className='text-danger'>{errors.contact_name}</span>}
            </div>
            <div className="col-md-6">
                <label>อีเมล</label>
                <input type="email" name="contact_email" value={contact.contact_email} onChange={handleChange} className='form-control'/>
                {errors.contact_email && <span className='text-danger'>{errors.contact_email}</span>}
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-md-4">
                <label>หมายเลขโทรศัพท์</label>
                <input type="text" name="contact_phone" onKeyDown={handleKeyNumberDigitsCheck} value={contact.contact_phone} maxLength={10} onChange={handleChange} required className='form-control'
                />
                {errors.contact_phone && <span className='text-danger'>{errors.contact_phone}</span>}
            </div>
            <div className="col-md-4">
                <label>หมายเลขเพิ่มเติม</label>
                <input type="text" name="contact_tel" onKeyDown={handleKeyNumberDigitsCheck} value={contact.contact_tel} maxLength={10} onChange={handleChange} className='form-control'/>
                {errors.contact_tel && <span className='text-danger'>{errors.contact_tel}</span>}
            </div>
            <div className='col-md-4'>
                <label>เลขที่ผู้เสียภาษี</label>
                <input type="text" name="contact_taxid" maxLength={13} onKeyDown={handleKeyNumberDigitsCheck} value={contact.contact_taxid} onChange={handleChange} className='form-control'/>
                {errors.contact_taxid && <span className='text-danger'>{errors.contact_taxid}</span>}
            </div>
        </div>
        <div className="row mt-3">
            <div className='col-md-12'>
                <label>ที่อยู่ลูกค้า</label>
                <textarea name="contact_address" value={contact.contact_address} onChange={handleChange} className='form-control'/>
                {errors.contact_address && <span className='text-danger'>{errors.contact_address}</span>}
            </div>
        </div>
        <div className='col-md-12 mt-4'>
            <button className='btn btn-secondary col-12' type="submit">เพิ่มรายการติดต่อ</button>
        </div>
    </form>
  );
};

export default InsertContact;

import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import ShortText from '../Function/Text';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

export default function ShowBarcodeQueues({ UpdateStatus }) {
    const [BarcodePrintingQueues, setBarcodePrintingQueues] = useState([]);
    const MySwal = withReactContent(Swal);
    const SwalShow = (status, message) => {
        if(status == true){
            MySwal.fire({
                title: 'สำเร็จ',
                text: message,
                icon: 'success'
            })
        } else{
            MySwal.fire({
                title: 'ไม่สำเร็จ',
                text: message,
                icon: 'error'
            })
        }
    };

    const GetBarcodePrintingData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SERVER}/GetBarcodePrinting`);
            if (response.data.Status) {
                setBarcodePrintingQueues(response.data.Result);
                console.log(response.data.Result);
            }
        } catch (error) {
            console.error('There was an error fetching the barcode printing data!', error);
        }
    };

    const DeleteBarcodePrintingData = async (BarcodePrintingGenID) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SERVER}/DeleteBarcodePrinting/${BarcodePrintingGenID}`);
            if (response.data.Status) {
                SwalShow(response.data.Status, response.data.message);
                UpdateStatus(true);
                // increment();
                setBarcodePrintingQueues(prevQueues => prevQueues.filter(queue => queue.bp_genid !== BarcodePrintingGenID));
            }
        } catch (error) {
            console.error('There was an error fetching the barcode printing data!', error);
        }
    };

    useEffect(() => {
        GetBarcodePrintingData();
    }, [UpdateStatus]);

    const increment = useCallback(() => {
        GetBarcodePrintingData();
    }, [UpdateStatus]);


    const BarcodePrintingQueuesComponent = ({ queues }) => {
        if (queues.length === 0) {
            return null;
        }

        return (
            <ol className="list-group">
                {queues.map(queue => (
                    <li key={queue.bp_id} className="list-group-item">
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="row">
                                <div className="">
                                    {ShortText(queue.bp_name, 35)}
                                </div>
                            </div>
                            <span className="badge text-bg-secondary rounded-pill">
                                {"#" + queue.bp_barcode + " "}
                            </span>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12 text-start">
                                <div className="d-flex justify-content-between align-items-start">
                                    <span className="badge text-bg-success rounded-pill">
                                        {queue.bp_price === 0 ? '0.00' : queue.bp_price}
                                    </span>
                                    <span className="badge text-bg-danger rounded-pill cursor-pointer" onClick={() => DeleteBarcodePrintingData(queue.bp_genid)} >
                                        {"ลบ"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ol>
        );
    };

    return (
        <div className="row" onClick={increment}>
            <div className="col-12 scrollspy-example scrollspy-pos">
                <BarcodePrintingQueuesComponent queues={BarcodePrintingQueues} />
            </div>
        </div>
    );
}

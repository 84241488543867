import {React, useEffect, useState} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';



export default function Discount({BillReference, BillData, AllItemBag, NowPrice}) {
    const [DiscountCode, setDiscountCode] = useState("");
    const [CancelDiscount, setCancelDiscount] = useState({
        Status: false,
        Message: ""
    });
    const MySwal = withReactContent(Swal);

    const handleChangeDiscount = (e) => {
        setDiscountCode(e.target.value);
    }

    const handleSubmitDiscount = async (e) => {
        e.preventDefault();
        console.log(DiscountCode);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/AddDiscount/${BillReference}`, {
                DiscountCode: DiscountCode,
                NowPrice: NowPrice
            },
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            });
            if(res.status == 200){
                SwalShow(res.data.status, res.data.message);
            }
            console.log(res);
        } catch (err) {
            console.error('Error fetching bill history', err);
        }
    }

    const handleCancelDiscount = async () => {
        console.log(DiscountCode);
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/CancelDiscount/${BillReference}`, 
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            });
            if(res.status == 200){
                setDiscountCode('');
                SwalShow(res.data.status, res.data.message);
            }
            console.log(res);
        } catch (err) {
            console.error('Error fetching bill history', err);
        }
    }

    const SwalShow = (status, message) => {
        if(status == true){
            MySwal.fire({
                title: 'สำเร็จ',
                text: message,
                icon: 'success'
            })
        } else{
            MySwal.fire({
                title: 'ไม่สำเร็จ',
                text: message,
                icon: 'error'
            })
        }
    };

    useEffect(() => {
        if(BillData.bill_dis_amount > 0){
            if(AllItemBag.length == 0){
                handleCancelDiscount();
                setCancelDiscount({
                    Status: true,
                    Message: "หากไม่มีในสินค้าในกระเป๋า ส่วนลดจะถูกยกเลิกอัตโนมัติ"
                });
            } else if(NowPrice.TotalPrice < NowPrice.Discount){
                handleCancelDiscount();
                setCancelDiscount({
                    Status: true,
                    Message: "ไม่สามารถใช้ส่วนลดได้ เนื่องจากมีมูลค่ามากกว่าราคาสินค้า"
                });
            } else{
                setDiscountCode(BillData.bill_dis_code);
            }
        }
        console.log(NowPrice.TotalPrice, NowPrice.Discount);
        setTimeout(() => {
            setCancelDiscount({
                Status: false,
                Message: ""
            });
        }, 10000);


    
    }, [AllItemBag])
    

  return (
    <div className="row" style={{marginTop: '0.5rem'}}>
        {
            (BillData.st_status == "process" && BillData.bill_dis_amount == undefined) && (AllItemBag.length > 0) ? (
                <>
                    <form onSubmit={handleSubmitDiscount}>
                        <div className="row">
                            <div className="col-9">
                                <input type="text" onChange={handleChangeDiscount} placeholder="กรอกส่วนลด" defaultValue={DiscountCode} value={DiscountCode} className="form-control" />
                            </div>    
                            <div className="col-3">
                                <button type='submit' className="btn btn-info col-12">ใช้</button>
                            </div>
                        </div>
                    </form>
                </>
            ) : (BillData.st_status == "process") && (BillData.bill_dis_amount > 0) ? (
                <>
                    <div className="col-9">
                        <input type="text" disabled={true} defaultValue={BillData.bill_dis_code} className="form-control" />
                    </div>
                    <div className="col-3">
                        <button type='button' onClick={handleCancelDiscount} className="btn btn-danger col-12">ยกเลิก</button>
                    </div>
                </>
            ) : (BillData.st_status == "success") && (BillData.bill_dis_amount > 0) ? (
                <>
                    <div className="col-12">
                        <input type="text" disabled={true} defaultValue={`${BillData.bill_dis_code} | ลด ${BillData.bill_dis_amount} บาท`} className="form-control" />
                    </div>
                </>
            ) : (
                <>
                    <div className="col-9">
                        <input type="text" disabled={true} defaultValue={DiscountCode} value={DiscountCode} placeholder="ไม่สามารถใช้ส่วนลดได้ในขณะนี้" className="form-control" />
                    </div>
                    <div className="col-3">
                        <button type='button' disabled={true} className="btn btn-info col-12">ใช้</button>
                    </div>
                </>
            )
        }
        {
            (BillData.st_status == "process") && (CancelDiscount.Status == true) ? (
                <div className='text-danger text-left'>
                    {CancelDiscount.Message}
                </div>
            ) : null
        }
    </div>
  )
}

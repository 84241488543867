import React, { useState } from 'react'
import RegisterValidation from '../Validation/RegisterValidation'
import axios from 'axios'
import {Link, useNavigate} from 'react-router-dom'

export default function Register() {
    const [Values, setValues] = useState({
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        password: "",
        password_confirm: ""
    })

    const handleInput = (event) => {
        setValues(prev => ({...prev, [event.target.name]: event.target.value}))
    }

    const navigate = useNavigate();
    const [Errors, setErrors] = useState({})
    const [AlertHeaderError, setAlertHeaderError] = useState({})
    const [AlertHeaderSuccess, setAlertHeaderSuccess] = useState("")
    const handleSubmit = (event) =>{
        event.preventDefault();
        setErrors(RegisterValidation(Values));
        console.log(Errors);
        // console.log(Values);

        // if(Errors.firstname == "" && Errors.lastname == "" && Errors.email == "" && Errors.username == "" && Errors.password == "" && Errors.password_confirm == ""){
        if(JSON.stringify(Errors) == "{}"){
          console.log(Values);
          axios({
            url: `${process.env.REACT_APP_API_SERVER}/register`,
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            data: Values,
          })
            // Handle the response from backend here
            .then((res) => {
              console.log(res);
              setAlertHeaderSuccess("Success");
              setTimeout(() => {
                navigate('/Login');
              }, 1000);
            })
 
            // Catch errors if any
            .catch((err) => {
              // console.log(err);
              // console.log(err.response.status);
              if(err.response.status == 400){
                setAlertHeaderError(err.response.data.errors);
              }
            })
        }

    }


    const [showPasswords, setShowPasswords] = useState(false);
    const handleCheckboxChange = () => {
      setShowPasswords(!showPasswords);
    };
  

  return (
    <div className="vh-100">
      <div className='row mt-5'>
        <div className='col-md-4'></div>
        <div className='col-md-4 d-flex justify-content-center align-items-center'>
          <img className='cursor-pointer' onClick={() => navigate('/')} height='100' src={process.env.PUBLIC_URL + "/assets/smkheader.png"} />
        </div>
        <div className='col-md-4'></div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'></div>
          <div className='col-md-4 frame'>
          <div className='mb-3'>
            <h4 className='text-center'>Registration</h4>
            {AlertHeaderError && <h6 className="text-danger text-center">{AlertHeaderError[0]}</h6>}
            {AlertHeaderSuccess && <h6 className="text-success text-center">{AlertHeaderSuccess}</h6>}
          </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                {/* <label className="form-label">Firstname</label> */}
                <input type="text" onChange={handleInput} name="firstname" placeholder="Firstname" className="form-control"/>
                {Errors.firstname && <div className="form-text text-danger">{Errors.firstname}</div>}
              </div>
              <div className="mb-3">
                {/* <label className="form-label">Lastname</label> */}
                <input type="text" onChange={handleInput} name="lastname" placeholder="Lastname" className="form-control"/>
                {Errors.lastname && <div className="form-text text-danger">{Errors.lastname}</div>}
              </div>
              <div className="mb-3">
                {/* <label className="form-label">Username</label> */}
                <input type="text" onChange={handleInput} name="username" placeholder="Username" className="form-control"/>
                {Errors.username && <div className="form-text text-danger">{Errors.username}</div>}
              </div>
              <div className="mb-3">
                {/* <label className="form-label">Email</label> */}
                <input type="email" onChange={handleInput} name="email" placeholder="Email" className="form-control"/>
                {Errors.email && <div className="form-text text-danger">{Errors.email}</div>}
              </div>
              <div className="mb-3">
                {/* <label htmlFor="exampleInputPassword1" className="form-label">Password</label> */}
                <input type={showPasswords ? 'text' : 'password'} onChange={handleInput} name="password" placeholder="Password" className="form-control" />
                {Errors.password && <div className="form-text text-danger">{Errors.password}</div>}
              </div>
              <div className="mb-3">
                {/* <label htmlFor="exampleInputPassword1" className="form-label">Confirm Password</label> */}
                <input type={showPasswords ? 'text' : 'password'} onChange={handleInput} name="password_confirm" placeholder="Password Confirmation" className="form-control" />
                {Errors.password_confirm && <div className="form-text text-danger">{Errors.password_confirm}</div>}
              </div>
              <div className="mb-3">
                <input type="checkbox" id="showPasswords" className="form-check-input" checked={showPasswords} onChange={handleCheckboxChange}/>
                <label className="form-check-label">แสดงรหัสผ่าน</label>
              </div>
              <div className='row'>
                <div className="col-12">
                  <input type="submit" className="btn btn-secondary col-12" value="Register" />
                    {/* <Link to="/register" type="button" className="btn btn-secondary col-12">Register</Link> */}
                </div>
                {/* <div className="col-6">
                    <Link to="/" type="button" className="btn btn-secondary col-12">I have an account</Link>
                </div> */}
              </div>
            </form>
          </div>
          <div className='col-md-4'></div>
        </div>
      </div>
    </div>
  )
}
